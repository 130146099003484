import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import server from '../../../global/server';
import { useApp } from '../../../context/AppContext';

const AuthContext = createContext({});

export const AuthProvider = props => {
    const { setLoading } = useApp();
    const navigate = useNavigate();

    const [token, setToken] = useState();
    const [user, setUser] = useState({});

    const verifySession = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const sessionUser = localStorage.getItem('user');
            if (!Boolean(token)) throw 'Not token';

            const user = JSON.parse(sessionUser);
            if (!Boolean(user.team)) throw 'Force logout';

            server.defaults.headers['Authorization'] = `Bearer ${token}`;
            setUser(user);
            setToken(token);
            setLoading(false);
        } catch (error) {
            delete server.defaults.headers.Authorization;
            setToken();
            setLoading(false);
        }
    };

    useEffect(() => {
        verifySession();
    }, []);

    const LogOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        verifySession();
        navigate('/');
    };

    return <AuthContext.Provider value={{ logged: Boolean(token), user, LogOut, verifySession, setUser }}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw 'Use Auth Context inside Auth Provider';

    return context;
};
