import { Box } from '@mui/material';
import Header from './Header';

export default props => {
    return (
        <>
            <Header />
            <Box flexGrow={1}>{props.children}</Box>
        </>
    );
};
