import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedLayout } from '../components/ProtectedLayout';
import Login from '../modules/Auth/screens/Login';
import Dashboard from '../modules/Dashboard';

import Customers from '../modules/Customers';
import CustomersList from '../modules/Customers/screens/CustomersList';
import CustomerDetails from '../modules/Customers/screens/CustomerDetails';

import Professional from '../modules/Professional';
import ProfessionalsList from '../modules/Professional/screens/ProfessionalsList';
import ProfessionalDetails from '../modules/Professional/screens/ProfessionalDetails';
import Settings from '../modules/Settings/screens';
import UsersList from '../modules/Users/screens/UsersList';
import UsersDetails from '../modules/Users/screens/UsersDetails';
import Users from '../modules/Users';
 
export default () => {
    return (
        <Routes>
            <Route index path="/" element={<Login />} />
            {/* <Route path="/profile/login" element={<LoginProfessional />} />
            <Route path="/profile/register" element={<ProfessionalRegister />} />
            <Route path="/profile/recovery" element={<LostPass />} /> */}

            <Route element={<ProtectedLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="customers" element={<Customers />}>
                    <Route index element={<CustomersList />} />
                    <Route path=":id" element={<CustomerDetails />} />
                </Route>
                <Route path="professional" element={<Professional />}>
                    <Route index element={<ProfessionalsList />} />
                    <Route path=":id" element={<ProfessionalDetails />} />
                </Route>

                <Route path="settings" element={<Settings />} />
                <Route path="users" element={<Users />}>
                    <Route index element={<UsersList />} />
                    <Route path=":id" element={<UsersDetails />} />
                </Route>
            </Route>

            {/* <Route element={<ProtectedProfile />}>
                <Route path="profile" element={<Profile />} />
            </Route> */}
        </Routes>
    );
};
