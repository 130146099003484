import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Checkbox, Container, Divider, FormControlLabel, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconFile from '@mui/icons-material/FileDownload';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useHandleErrors from '../../../hooks/useHandleErrors';
import server from '../../../global/server';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

export default ({ data }) => {
    const [errs, setErrs] = useState();
    const { id: customer_id } = useParams();
    useHandleErrors(errs);

    const { enqueueSnackbar } = useSnackbar();

    const { values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setValues } = useFormik({
        initialValues: {
            amount_km_big: '',
            amount_km_middle: '',
            amount_km_small: '',
            min_amount_big: '',
            min_amount_middle: '',
            min_amount_small: '',
            tax_roadmap_point_small: '',
            tax_roadmap_point_middle: '',
            tax_roadmap_point_big: '',
            status: false,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);

                await server.put(`/customer/${customer_id}/taxrules`, values);
                enqueueSnackbar('Configuração alterada');
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            if (data.taxsRules) {
                console.log(data.taxsRules)
                setValues(data.taxsRules, true);
            }
        }
    }, [data]);

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={values.status} onChange={handleChange} name="status" />}
                            label={values.status ? 'Ativo' : 'Inativo'}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Valor por km/Pequeno"
                            type="text"
                            name="amount_km_small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.amount_km_small.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.amount_km_small}
                            helperText={errors.amount_km_small}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Valor por km/Medio"
                            type="text"
                            name="amount_km_middle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.amount_km_middle.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.amount_km_middle}
                            helperText={errors.amount_km_middle}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Valor por km/Grande"
                            type="text"
                            name="amount_km_big"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.amount_km_big.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.amount_km_big}
                            helperText={errors.amount_km_big}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Divider />
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Franquia/Pequeno"
                            type="text"
                            name="min_amount_small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.min_amount_small.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.min_amount_small}
                            helperText={errors.min_amount_small}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Franquia/Médio"
                            type="text"
                            name="min_amount_middle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.min_amount_middle.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.min_amount_middle}
                            helperText={errors.min_amount_middle}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Franquia/Grande"
                            type="text"
                            name="min_amount_big"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.min_amount_big.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.min_amount_big}
                            helperText={errors.min_amount_big}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Divider />
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Adicional por ponto/Pequeno"
                            type="text"
                            name="tax_roadmap_point_small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_roadmap_point_small.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_roadmap_point_small}
                            helperText={errors.tax_roadmap_point_small}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Adicional por ponto/Médio"
                            type="text"
                            name="tax_roadmap_point_middle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_roadmap_point_middle.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_roadmap_point_middle}
                            helperText={errors.tax_roadmap_point_middle}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Adicional por ponto/Grande"
                            type="text"
                            name="tax_roadmap_point_big"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_roadmap_point_big.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_roadmap_point_big}
                            helperText={errors.tax_roadmap_point_big}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>

                    <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid>
                            <LoadingButton onClick={handleSubmit} loading={isSubmitting} variant="contained">
                                Salvar dados
                            </LoadingButton> 
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
