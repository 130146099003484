import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/AddCircle';
import ExitIcon from '@mui/icons-material/ExitToApp';
import ConfigIcon from '@mui/icons-material/Settings';
import UsersIcon from '@mui/icons-material/VerifiedUser';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OrdersIcon from '@mui/icons-material/FileOpen';
import CustomerIcon from '@mui/icons-material/Person';
import ProfessionalIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import UserIcon from '@mui/icons-material/VerifiedUser';

import { useApp } from '../context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../modules/Auth/context/AuthContext';

const AppBarAdmin = ({ setOpenMenu, filter, setOpenFilter }) => {
    const { LogOut, user } = useAuth();
    const { pathname } = useLocation();
    const navigation = useNavigate();

    return (
        <AppBar position="static">
            <Toolbar>
                {/* <IconButton onClick={() => setOpenMenu(true)} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton> */}

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Frete Fácil
                </Typography>

                <Button onClick={() => navigation('dashboard')} variant="text" sx={{ color: '#fff', marginRight: 1 }} endIcon={<OrdersIcon />}>
                    Pedidos em aberto
                </Button>
                <Button onClick={() => navigation('customers')} variant="text" sx={{ color: '#fff', marginRight: 1 }} endIcon={<CustomerIcon />}>
                    Clientes
                </Button>
                <Button
                    onClick={() => navigation('professional')}
                    variant="text"
                    sx={{ color: '#fff', marginRight: 1 }}
                    endIcon={<ProfessionalIcon />}
                >
                    Profissionais
                </Button>
                <Button onClick={() => navigation('users')} variant="text" sx={{ color: '#fff', marginRight: 1 }} endIcon={<UserIcon />}>
                    Usuários
                </Button>
                <Button onClick={() => navigation('settings')} variant="text" sx={{ color: '#fff', marginRight: 1 }} endIcon={<SettingsIcon />}>
                    Configurações
                </Button>
                <Button variant="text" sx={{ color: '#fff' }} onClick={() => LogOut()} endIcon={<ExitIcon />}>
                    Sair
                </Button>
            </Toolbar>
        </AppBar>
    );
};

// const AppBarProfessional = ({ setOpenMenu }) => {
//     const { LogOut, user } = useAuth();
//     return (
//         <AppBar position="static">
//             <Toolbar>
//                 <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//                     Mount
//                 </Typography>
//                 <Button variant="text" sx={{ color: '#fff' }} endIcon={<DownloadIcon />}>
//                     Baixar curriculo
//                 </Button>

//             </Toolbar>
//         </AppBar>
//     );
// };
export default props => {
    const { openMenu, setOpenMenu, filter, setOpenFilter } = useApp();
    const { LogOut, user } = useAuth();
    const navigation = useNavigate();

    return (
        <>
            <AppBarAdmin setOpenMenu={setOpenMenu} filter={filter} setOpenFilter={setOpenFilter} />

            <Drawer anchor="left" open={openMenu} onClose={() => setOpenMenu(false)}>
                <Box sx={{ width: 280 }} role="presentation" onClick={() => setOpenMenu(false)}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/dashboard')}>
                                <ListItemIcon>
                                    <HomeIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/professional/new')}>
                                <ListItemIcon>
                                    <AddIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Novo profissional" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/settings')}>
                                <ListItemIcon>
                                    <ConfigIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Configurações" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/users')}>
                                <ListItemIcon>
                                    <UserIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => LogOut()}>
                                <ListItemIcon>
                                    <ExitIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Sair" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </Box>
            </Drawer>
        </>
    );
};
