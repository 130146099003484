import {
    AppBar,
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    Divider,
    Drawer,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import LoadingContent from '../../components/LoadingContent';
import { useApp } from '../../context/AppContext';
import { useFetch } from '../../hooks/useFetch';
import OrdersDetails from './screens/OrdersDetails';
import CloseIcon from '@mui/icons-material/Close';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import size from '../../global/size';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import lodash from 'lodash';
import { status } from '../../global/helpers';
import gregorian_pt from '../../global/gregorian_pt';
import DatePicker from 'react-multi-date-picker';

export default props => {
    const [filterView, setFilterView] = useState(false);
    const [orderId, setOrderId] = useState();

    const [searchParams, setSearchParams] = useSearchParams({ status: 'ANALYSE' });
    const { handleChange, handleSubmit, values, setValues, setFieldValue } = useFormik({
        initialValues: {
            status: '',
            id: '',
            origin: '',
            destination: '',
            scheduled: '',
            document: '',
            email: '',
        },
        onSubmit: values => {
            setSearchParams({ ...lodash.omitBy(values, v => v === ''), status: values.status });
        },
    });

    const PickerDate = ({ openCalendar, value, separator, name, label, ...props }) => {
        value = value.split(separator);
        const viewValue = !Boolean(value[0])
            ? null
            : value.length === 1
            ? new Date(value[0]).toLocaleDateString()
            : value.length === 2
            ? `De ${new Date(value[0]).toLocaleDateString()} a ${new Date(value[1]).toLocaleDateString()}`
            : null;

        return (
            <TextField
                placeholder="Selecione uma ou mais datas"
                fullWidth
                value={viewValue}
                label={label}
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="end" sx={{ display: Boolean(viewValue) ? 'inherit' : 'none' }}>
                                <IconButton onClick={() => setFieldValue(name, '')}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                            <InputAdornment position="start">
                                <IconButton onClick={openCalendar}>
                                    <CalendarIcon />
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />
        );
    };

    useEffect(() => {
        if (searchParams) {
            let toFields = Object.fromEntries(searchParams);
            toFields.scheduled = searchParams.getAll('scheduled') || undefined;
            setValues(toFields, false);
        }
    }, [searchParams]);

    const { data, error, mutate, isFetching } = useFetch('/packages', {
        params: searchParams,
    });

    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'owner',
            headerName: 'Cliente',
            flex: 1,
            valueGetter: ({ value }) => value.name,
        },
        // {
        //     field: 'createdAt',
        //     headerName: 'Criado em',
        //     type: 'date',
        //     width: 160,
        //     valueGetter: ({ value }) => new Date(value).toLocaleString('pt-BR'),
        // },
        {
            field: 'updatedAt',
            headerName: 'Atualizado em',
            type: 'date',
            width: 160,
            valueGetter: ({ value }) => new Date(value).toLocaleString('pt-BR'),
        },
        {
            field: 'scheduled',
            headerName: 'Agendamento',
            type: 'date',
            width: 120,
            valueGetter: params => `${params.row.scheduled ? new Date(params.row.scheduled).toLocaleDateString('pt-BR') : 'Imediato'}`,
        },
        {
            field: 'origin',
            headerName: 'Origem',
            width: 200,

            valueGetter: params => `${params.row.origin_district || ''}${', ' + params.row.origin_city || ''}`,
        },
        {
            field: 'destination',
            headerName: 'Destino',
            width: 200,
            valueGetter: params => `${params.row.destination_district || ''}${', ' + params.row.destination_city || ''}`,

            //editable: true,
        },
        {
            field: 'size',
            headerName: 'Tamanho',
            width: 90,
            valueGetter: params => size(params.value),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            valueGetter: ({ value }) => status[value],
        },
        // {
        //     field: 'amount',
        //     headerName: 'Valor',
        //     valueGetter: params => `${ Number(params.row.amount).toLocaleString('pt-BR',{maximumFractionDigits:2, minimumFractionDigits: 2}) || '--'}`,
        //     width: 90,
        // },
        // {
        //     field: 'customer',
        //     headerName: 'Cliente',
        //     width: 210,
        //     valueGetter: params => params.row.owner.name,
        //     //editable: true,
        // },
        {
            field: 'actions',
            //headerName: 'actions',
            type: 'actions',
            renderCell: params => (
                <Button variant="outlined" onClick={() => setOrderId(params.row.id)} size="small">
                    Detalhes
                </Button>
            ),
        },
    ]);

    //if (!data) return <LoadingContent label="Carregando lista" />;

    return (
        <>
            <Drawer anchor="right" variant="temporary" open={filterView} onClose={e => setFilterView(false)}>
                <Toolbar>
                    <Typography variant="h6">Filtros</Typography>
                    <Divider />
                </Toolbar>
                <Box sx={{ width: 350, p: 3 }}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="ID do Pedido"
                                name="id"
                                value={values.id}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setOrigin()}>
                                //             {origin && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel id="edducation">Status</InputLabel>
                                <Select labelId="edducation" id="status" name="status" value={values.status} label="Status" onChange={handleChange}>
                                    <MenuItem value="">TODOS</MenuItem>
                                    <MenuItem value="DRAFT">{status['DRAFT']}</MenuItem>
                                    <MenuItem value="ANALYSE">{status['ANALYSE']}</MenuItem>
                                    <MenuItem value="WAITING">{status['WAITING']}</MenuItem>
                                    <MenuItem value="IN_PROGRESS">{status['IN_PROGRESS']}</MenuItem>
                                    <MenuItem value="CONCLUDED">{status['CONCLUDED']}</MenuItem>
                                    <MenuItem value="PAYMENT_FAIL">{status['PAYMENT_FAIL']}</MenuItem>
                                    <MenuItem value="DISAPPROVED">{status['DISAPPROVED']}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <DatePicker
                                containerStyle={{ width: '100%' }}
                                onChange={e => setFieldValue('scheduled', e)}
                                type="date"
                                range
                                locale={gregorian_pt}
                                format="MM/DD/YYYY"
                                // style={{ height: '3.5rem', width: '100%', fontSize: '1rem', fontWeight: '700' }}
                                // containerStyle={{ width: '100%' }}
                                // placeholder="Coloque as datas referentes aos serviços aqui"
                                //minDate={new Date()}
                                value={values.scheduled}
                                dateSeparator=" a "
                                required
                                render={<PickerDate name="scheduled" label="Data do agendamento" />}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setOrigin()}>
                                //             {origin && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="Documento"
                                name="document"
                                value={values.document}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setOrigin()}>
                                //             {origin && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid>
                        {/* <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="Origem"
                                name="origin"
                                value={values.origin}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setOrigin()}>
                                //             {origin && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="Destino"
                                name="destination"
                                value={values.destination}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setDestination()}>
                                //             {destination && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid> */}

                        <Grid item xs>
                            <Button variant="contained" onClick={handleSubmit}>
                                Aplicar filtros
                            </Button>
                            <Button
                                onClick={e => {
                                    setValues({ status: 'ANALYSE' }, false);
                                    handleSubmit();
                                    setFilterView();
                                }}
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
            <Container maxWidth="xl" fixed>
                <Stack direction="row" my={2} alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" component="h2" mb={3}>
                        Dashboard de pedidos
                    </Typography>
                    <Button variant="contained" onClick={e => setFilterView(true)}>
                        Filtros
                    </Button>
                </Stack>
                {/* <Grid container direction="row" alignItems="center" justifyContent="flex-end" sx={{ marginBottom: 2 }}>
                    <Grid item xs>
                          <Stack direction="row"  alignItems="flex-end" justifyContent="flex-end" spacing={1}>
                            {Object.entries(values).map(item => {
                                return (
                                <Chip variant="outlined" color="primary" label={`${item[0]}: ${status[item[1]]}`} />
                            )})}
                        </Stack>  }
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'right' }}>
                        
                    </Grid>
                </Grid> */}

                <Grid container sx={{ height: 650 }}>
                    <DataGrid
                        //paginationMode="server"
                        loading={isFetching}
                        rows={data?.rows || []}
                        columns={columns}
                        //rowsPerPageOptions={[10, 25, 50, 100]}
                    />
                </Grid>
            </Container>
            <Dialog fullWidth fullScreen maxWidth="lg" open={Boolean(orderId)} scroll="paper">
                {Boolean(orderId) && <OrdersDetails mutateList={mutate} handleClose={() => setOrderId()} id={orderId} />}
            </Dialog>
        </>
    );
};
