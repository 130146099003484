import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { Container } from '@mui/material';
import ProfessionaTabs from '../components/ProfessionalsTabs';

export default function ProfessionalDetails() {
    const [activeTab, setActiveTab] = useState(0);
    const { id } = useParams();

    return (
        <Container fixed>
            <Tabs value={activeTab} onChange={(evt, activedTab) => setActiveTab(activedTab)}>
                <Tab label="Dados do motorista" />
                <Tab label="Dados jurídicos" />
                <Tab label="Endereço" />
                <Tab label="Referência" />
                <Tab label="Veículo" />
            </Tabs>
            <ProfessionaTabs activeTab={activeTab} />
        </Container>
    );
}
