import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Container, Dialog, DialogContent, DialogTitle, Divider, Link, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconFile from '@mui/icons-material/FileDownload';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useHandleErrors from '../../../hooks/useHandleErrors';
import server from '../../../global/server';

export default ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);
    const { values, errors, handleChange, handleSubmit, handleBlur, handleReset, isSubmitting, setValues } = useFormik({
        initialValues: {
            street: '',
            city: '',
            complement: '',
            neighborhood: '',
            state: '',
            zipcode: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`/customer/${data.id}/address`, values);
                enqueueSnackbar('Dados alterados');
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
             
            const { street, city, complement, state, neighborhood, zipcode } = data?.Address || {};
            setValues(
                {
                    street,
                    city,
                    complement,
                    state,
                    neighborhood,
                    zipcode,
                },
                false,
            );
        }
    }, [data]);

    const [dialog, setDialog] = useState(false);

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Endereço"
                            type="text"
                            name="street"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.street}
                            error={errors.street}
                            helperText={errors.street}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Cidade"
                            type="text"
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            error={errors.city}
                            helperText={errors.city}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Complemento"
                            type="text"
                            name="complement"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.complement}
                            error={errors.complement}
                            helperText={errors.complement}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Bairro"
                            type="text"
                            name="neighborhood"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.neighborhood}
                            error={errors.neighborhood}
                            helperText={errors.neighborhood}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="UF"
                            type="text"
                            name="state"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            error={errors.state}
                            helperText={errors.state}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="CEP"
                            type="text"
                            name="zipcode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zipcode}
                            error={errors.zipcode}
                            helperText={errors.zipcode}
                        />
                    </Grid>
                    {/* <Grid item xs={4}>
                        <Button endIcon={<IconFile />} variant="text" onClick={() => setDialog(true)}>
                            Comprovante de endereço
                        </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid>
                            <LoadingButton disabled={isSubmitting} loading={isSubmitting} variant="contained" onClick={handleSubmit}>
                                Salvar dados
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Dialog open={dialog} fullWidth maxWidth="sm" onClose={() => setDialog(false)}>
                    <DialogTitle>Visualização - Comprovante de endereço</DialogTitle>

                    <DialogContent>
                        <img width="100%" height="100%" src={data?.Address?.address_file} />
                    </DialogContent>
                </Dialog> */}
            </Container>
        </>
    );
};
