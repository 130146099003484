import { Container, Typography } from '@mui/material';
import { useFetch } from '../../../hooks/useFetch';
import SettingsForm from '../components/SettingsForm';

export default function Settings() {
    const { data, error, mutate, isFetching } = useFetch('/config');

    return (
        <Container fixed>
            <Typography variant="h5" component="h2" my={3}>
                Configurações
            </Typography>
            <SettingsForm data={data} />
        </Container>
    );
}
