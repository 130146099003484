import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useAuth } from '../modules/Auth/context/AuthContext';

const useHandleErrors = errors => {
    const { LogOut } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (errors) {
            if (!Boolean(errors?.response)) {
                enqueueSnackbar('Servidor indisponível');
                return;
            }

            console.log(errors.response);

            switch (errors.response) {
                case 401:
                    LogOut();
                    break;

                case 200:
                    enqueueSnackbar(errors.response.data.message);
                    break;

                case 0:
                    enqueueSnackbar('Servidor desconectado.');
                    break;
                default:
                    if (errors.response) {
                        if (errors.response.data.message) {
                            enqueueSnackbar(errors.response.data.message);
                        } else {
                            enqueueSnackbar(errors.response.message);
                        }
                    } else {
                        enqueueSnackbar(errors.message);
                    }
                    break;
            }
        }
    }, [errors]);
};

export default useHandleErrors;
