import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Container, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';

export default ({ data, mutate }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { values, errors, handleChange, handleSubmit, handleBlur, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            email: '',
            cellphone: '',
            document: '',
            status: false,
            gender: '',
            birthday: '',
            createdAt: '',
            password: '',
            document_type: '',
            business_name: ''
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`/customer/${data.id}`, values);
                setFieldValue('password','') 
                enqueueSnackbar('Dados alterados');
                setSubmitting(false);
            } catch (error) {
                console.log(error.response);
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            console.log(data);
            const { name, document, document_type, business_name, email, cellphone, gender, birthday, status, createdAt } = data;
            setValues({ name, document, email, document_type, business_name, cellphone, gender, birthday, status, createdAt }, false);
        }
    }, [data]);

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack alignItems="flex-end">
                            <Alert>Cadastrado desde {new Date(values.createdAt).toLocaleString('pt-BR')}</Alert>
                        </Stack>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tipo do documento</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="document_type"
                                value={values?.document_type}
                                label="Tipo do documento"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            >
                                <MenuItem value="CPF">CPF</MenuItem>
                                <MenuItem value="CNPJ">CNPJ</MenuItem>
                            </Select>
                            <FormHelperText>{errors.document_type}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Documento"
                            type="text"
                            name="document"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.document}
                            error={errors.document}
                            helperText={errors.document}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Nome Fantasia/Razao Social"
                            type="text"
                            name="business_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.business_name}
                            error={errors.business_name}
                            helperText={errors.business_name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Nome"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={errors.name}
                            helperText={errors.name}
                        />
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="gender"
                                value={values?.gender}
                                label="Sexo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            >
                                <MenuItem value="male">Masculino</MenuItem>
                                <MenuItem value="female">Feminino</MenuItem>
                            </Select>
                            <FormHelperText>{errors.gender}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Telefone"
                            type="text"
                            name="cellphone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cellphone}
                            error={errors.cellphone}
                            helperText={errors.cellphone}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Email"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={errors.email}
                            helperText={errors.email}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Data de nascimento"
                            type="date"
                            name="birthday"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.birthday}
                            error={errors.birthday}
                            helperText={errors.birthday}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Senha"  
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={errors.password}
                            helperText={errors.password}
                        />
                    </Grid>
                    <Divider />
                    <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid>
                            <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                                Salvar dados
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
