import { Avatar, Box, Card, CardContent, CardHeader, Grid, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { status } from '../../../global/helpers';

export default function CardProfessional({ DeliveryOrder }) {
    const { User, DeliveryFlowHistories } = DeliveryOrder;

    return (
        <Card>
            <CardHeader avatar={<Avatar src={User.picture} />} title={User.name} subheader={`Contato: ${User.cellphone}` }/>

            <CardContent>
                <Stepper orientation="vertical">
                    {DeliveryFlowHistories?.map(flow => (
                        <Step key={flow.id} expanded={true} completed={true}>
                            <StepLabel>{status[flow.history] || '--'}</StepLabel>
                            <StepContent>
                                <Typography variant="caption">
                                    {new Date(flow.createdAt).toLocaleDateString()} {new Date(flow.createdAt).toLocaleTimeString()}
                                </Typography>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </CardContent>
        </Card>
    );
}
