export const zona = zona => (zona === 1 ? 'Zona Urbana' : 'Zona Rural');

export const centered = data => {
    const listaLat = data.map(item => item.rastreamento.split(',')[0]).sort();
    const listaLng = data.map(item => item.rastreamento.split(',')[1]).sort();

    const minLat = Number(listaLat[0]);
    const maxLat = Number(listaLat[listaLat.length - 1]);
    const minLng = Number(listaLng[0]);
    const maxLng = Number(listaLng[listaLng.length - 1]);

    return { lat: minLat + (maxLat - minLat) * 0.5, lng: minLng + (maxLng - minLng) * 0.5 };
};

export const languages = ['english', 'spanish', 'portuguese', 'french'];

export const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

//DRAFT //ANALYSE //WAITING //IN_PROGRESS //FINISHED //CONCLUDED //CANCELED //DISAPPROVED //PAYMENT_FAIL
export const status = {
    DRAFT: 'RASCUNHO',
    ANALYSE: 'PARA ANÁLISE',
    WAITING: 'AGUARDANDO ACEITE',
    IN_PROGRESS: 'EM PROGRESSO',
    FINISHED: 'FINALIZADO',
    CANCELED: 'CANCELADO',
    DISAPPROVED: 'REPROVADA',
    CONCLUDED: 'CONCLUIDO',
    PAYMENT_FAIL: 'PAGAMENTO FALHOU',
    
    PAID: 'PAGO',
    DELIVERED: 'ENTREGUE',
    COLLECTED: 'COLETADO',
    ACCEPTED: 'COLETA ACEITA',
    PAYMENT_SET: 'PAGAMENTO CONFIGURADO',
};
