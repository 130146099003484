import {
    Button,
    Chip,
    Container,
    Dialog,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import LoadingContent from '../../../components/LoadingContent';
import { useApp } from '../../../context/AppContext';
import { useFetch } from '../../../hooks/useFetch';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const ViewDetailsButton = ({ params, id }) => {
    const { orderId, setOrderId } = useApp();

    const navigation = useNavigate();
    return (
        <Button
            variant="outlined"
            onClick={() => navigation(`${id}`)}
            size="small"
            style={{ marginLeft: 16, fontSize: 10 }}
            tabIndex={params.hasFocus ? 0 : -1}
        >
            Detalhes
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
    },
    {
        field: 'name',
        headerName: 'Nome',
        width: 300,
        //editable: true,
    },
    {
        field: 'Professional',
        headerName: 'CNPJ',
        width: 200,
        valueGetter: ({row})=> row?.Professional?.business_document || '--'
        //editable: true,
    },
    {
        field: 'email',
        headerName: 'E-mail',
        width: 300,
    },
    {
        field: 'Professional.status', 
        headerName: 'Situação',
        width: 100,
        valueGetter: ({row})=> row?.Professional?.status? 'Ativo': 'Inativo',
        renderCell: ({value}) =>   <Chip color={value === 'Ativo'? 'success': 'error'} label={value}/> 
    }, 
    {
        field: 'actions',
        //headerName: 'actions',
        type: 'actions',
        renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
    },
];

export default props => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [business_document, setDocument] = useState();

    const [params, setParams] = useState();

    const handleSearch = () => {
        setParams({ status, name, email, business_document });
    };
    const { data, error, mutate, isFetching } = useFetch('/professionals', {
        params: {
            limit: pageSize,
            page: page,
            ...params,
        },
    });

    const { orderId, setOrderId } = useApp();

    if (!data) return <LoadingContent label="Carregando lista" />;

    const { rows, pagination } = data;
    return (
        <>
            <Container fixed>
                <Typography variant="h5" component="h2" my={3}>
                    Profissionais
                </Typography>

                <Grid container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            type="text"
                            label="Nome"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clean text"
                                        onClick={e => setName()}
                                    >
                                        {name && <CloseIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            type="text"
                            label="E-mail"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clean text"
                                        onClick={e => setEmail()}
                                    >
                                        {email && <CloseIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            type="text"
                            label="CNPJ"
                            value={business_document}
                            onChange={e => setDocument(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clean text"
                                        onClick={e => setDocument()}
                                        // onMouseDown={handleMouseDownPassword}
                                    >
                                        {business_document && <CloseIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton color="primary" size="large" variant="contained" onClick={handleSearch}>
                            <SearchIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container sx={{ height: 500 }}>
                    <DataGrid
                        paginationMode="server"
                        loading={isFetching}
                        autoPageSize
                        //rowCount={pageSize}
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        onPageChange={newPage => setPage(newPage)}
                        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        // checkboxSelection
                    />
                </Grid>
            </Container>
            {/* <Dialog fullWidth maxWidth="lg" open={Boolean(orderId)} scroll="paper">
                {orderId && <OrdersDetails handleClose={() => setOrderId()} id={orderId} />}
            </Dialog> */}
        </>
    );
};
