import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import LoadingContent from '../../../components/LoadingContent';
import { useApp } from '../../../context/AppContext';
import { useFetch } from '../../../hooks/useFetch';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import ProfileForm from '../components/ProfileForm';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const ViewDetailsButton = ({ params, id }) => {
    const { orderId, setOrderId } = useApp();

    const navigation = useNavigate();
    return (
        <Button
            variant="outlined"
            onClick={() => navigation(`${id}`)}
            size="small"
            style={{ marginLeft: 16, fontSize: 10 }}
            tabIndex={params.hasFocus ? 0 : -1}
        >
            Detalhes
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
    },
    {
        field: 'name',
        headerName: 'Nome',
        minWidth: 300,
        flex: 1
    },
    {
        field: 'username',
        headerName: 'Login',
        width: 200,
    },

    {
        field: 'actions',
        //headerName: 'actions',
        type: 'actions',
        renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
    },
];

export default props => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState();
    const [addNew, setAddNew] = useState(false);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [document, setDocument] = useState();
    const navigation = useNavigate();

    const [params, setParams] = useState();

    const handleSearch = () => {
        setParams({ status, name, email, document });
    };
    const { data, error, mutate, isFetching } = useFetch('/users', {
        params: {
            limit: pageSize,
            page: page,
            ...params,
        },
    });

    const { orderId, setOrderId } = useApp();

    if (!data) return <LoadingContent label="Carregando lista" />;

    const { rows, pagination } = data;
    return (
        <>
            <Container fixed>
                <Typography variant="h5" component="h2" my={3}>
                    Clientes
                </Typography>
                <Grid container sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Button variant="contained" onClick={e => setAddNew(true)}>
                            Adicionar novo
                        </Button>
                    </Grid>
                </Grid>

                {/*<Grid container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                     <Grid item xs>
                        
                        <FormControl fullWidth>
                            <InputLabel id="edducation">Status</InputLabel>
                            <Select
                                labelId="edducation"
                                id="status"
                                name="status"
                                value={status}
                                label="Status"
                                onChange={e => setStatus(e.target.value)}
                            >
                                <MenuItem value="">Todos</MenuItem>
                                <MenuItem value="DRAFT">Nao realizados</MenuItem>
                                <MenuItem value="ANALYZE">Em análise</MenuItem>
                                <MenuItem value="WAITING">Aguardando</MenuItem>
                                <MenuItem value="IN_PROGRESS">Em andamento</MenuItem>
                                <MenuItem value="CONCLUDED">Concluída</MenuItem>
                            </Select>
                        </FormControl>
                         
                    </Grid>  
                      <Grid item xs >
                        <TextField fullWidth label="Nome" value={name} onChange={e => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs >
                        <TextField fullWidth label="Email" value={email} onChange={e => setEmail(e.target.value)} />
                    </Grid> 
                    <Grid item xs >
                        <TextField
                            fullWidth
                            type="text"
                            label="Nome"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clean text"
                                        onClick={e => setName()}
                                        // onMouseDown={handleMouseDownPassword}
                                    >
                                        {name && <CloseIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs >
                        <TextField
                            fullWidth
                            type="text"
                            label="E-mail"
                            value={email}
                            onChange={e =>setEmail(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clean text"
                                        onClick={e =>setEmail()}
                                        // onMouseDown={handleMouseDownPassword}
                                    >
                                        {email && <CloseIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs >
                        <TextField
                            fullWidth
                            type="text"
                            label="CPF"
                            value={document}
                            onChange={e => setDocument(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clean text"
                                        onClick={e => setDocument()}
                                        // onMouseDown={handleMouseDownPassword}
                                    >
                                        {document && <CloseIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton color="primary" size="large" variant="contained" onClick={handleSearch}>
                            <SearchIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>*/}
                <Grid container sx={{ height: 500 }}>
                    <DataGrid
                        paginationMode="server"
                        loading={isFetching}
                        autoPageSize
                        //rowCount={pageSize}
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        onPageChange={newPage => setPage(newPage)}
                        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        // checkboxSelection
                    />
                </Grid>
            </Container>
            <Dialog fullWidth maxWidth="md" open={Boolean(addNew)} scroll="paper" onClose={e => setAddNew(false)}>
                <DialogTitle>
                    Adicionar novo usuário
                    <IconButton
                        aria-label="close"
                        onClick={e => setAddNew(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ProfileForm mutate={mutate} />
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={e => setAddNew(true)}>Cancelar</Button>
                    <LoadingButton>Salvar</LoadingButton>
                </DialogActions> */}
            </Dialog>
        </>
    );
};
