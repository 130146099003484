import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Box,
    Button,
    Card,
    CardMedia,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconFile from '@mui/icons-material/FileDownload';
import IconPhoto from '@mui/icons-material/CameraAlt';
import IconTrash from '@mui/icons-material/DeleteForever';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import server from '../../../global/server';
import { useSnackbar } from 'notistack';
import useHandleErrors from '../../../hooks/useHandleErrors';
import ActivateProfessional from './ActivateProfessional';
import DeleteProfessional from './DeleteProfessional';

export default ({ data, mutate }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);
    const { values, errors, handleChange, handleSubmit, handleBlur, setFieldValue, isSubmitting, setValues } = useFormik({
        initialValues: {
            name: '',
            document: '',
            email: '',
            cellphone: '',
            document_file: '',
            document_file_back: '',
            status: true,
            gender: '',
            birthday: '',
            picture: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`/professional/${data.id}/user`, values);
                await mutate();
                enqueueSnackbar('Dados alterados');
                setSubmitting(false);
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const { name, document, email, cellphone, gender, birthday, picture } = data;
            const { status, document_file, document_file_back } = data?.Professional || {};
            setValues({ name, document, email, cellphone, gender, birthday, picture, status, document_file, document_file_back }, false);
        }
    }, [data]);

    const [dialog, setDialog] = useState(false);

    const handleImage = (event, name) => {
        const image = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            setFieldValue(name, reader.result); //
        };
        reader.onerror = e => console.error(e);
    };

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2} rowGap={2}>
                    <Grid item xs={12}>
                        <Stack alignItems="flex-end">
                            {Boolean(data.Professional) && <Alert severity="info">Cadastrado desde {new Date(data.Professional.createdAt).toLocaleString()}</Alert>}
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia component="img" height="240" image={values.picture} alt="" />
                        </Card>
                        <Stack pt={2}>
                            <ActivateProfessional account_id={data.id} status={values.status} mutate={mutate} />
                        </Stack>
                        <Stack pt={2}>
                            <Button variant="contained" startIcon={<IconPhoto />} component="label" onChange={e => handleImage(e, 'picture')}>
                                <Box name="picture" value={values.picture} onChange={handleChange}>
                                    Mudar foto
                                    <input type="file" hidden accept="image/jpeg, image/jpg, image/png" />
                                </Box>
                            </Button>
                        </Stack>
                        <Stack pt={2}>
                            <DeleteProfessional account_id={data.id}  />
                        </Stack>
                    </Grid>
                    <Grid xs item container>
                        <Stack width="100%">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Nome"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        error={errors.name}
                                        helperText={errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Documento"
                                        type="text"
                                        name="document"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.document}
                                        error={errors.document}
                                        helperText={errors.document}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="gender"
                                            value={values?.gender}
                                            label="Sexo"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="male">Masculino</MenuItem>
                                            <MenuItem value="female">Feminino</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors.gender}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Telefone"
                                        type="text"
                                        name="cellphone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.cellphone}
                                        error={errors.cellphone}
                                        helperText={errors.cellphone}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Email"
                                        type="text"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        error={errors.email}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Data de nascimento"
                                        type="date"
                                        name="birthday"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.birthday}
                                        error={errors.birthday}
                                        helperText={errors.birthday}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button endIcon={<IconFile />} onClick={() => setDialog(true)} variant="text">
                                        Documento - CNH
                                    </Button>
                                </Grid>
                            </Grid>
                        </Stack>

                        <Divider />
                    </Grid>
                    <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid>
                            <LoadingButton loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit} variant="contained">
                                Salvar dados
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={dialog} fullWidth maxWidth="md" onClose={() => setDialog(false)}>
                    <DialogTitle>Visualização - CNH</DialogTitle>

                    <DialogContent>
                        <Stack direction="row" spacing={2}>
                            <Box>
                                <img style={{ maxWidth: '100%' }} src={values.document_file} />
                            </Box>
                            <Box>
                                <img style={{ maxWidth: '100%' }} src={values.document_file_back} />
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" startIcon={<IconPhoto />} component="label" onChange={e => handleImage(e, 'document_file')}>
                            <Box name="document_file" value={values.document_file} onChange={handleChange}>
                                Mudar frente
                                <input type="file" hidden accept="image/jpeg, image/jpg, image/png" />
                            </Box>
                        </Button>
                        <Button variant="contained" startIcon={<IconPhoto />} component="label" onChange={e => handleImage(e, 'document_file_back')}>
                            <Box name="document_file_back" value={values.document_file_back} onChange={handleChange}>
                                Mudar verso
                                <input type="file" hidden accept="image/jpeg, image/jpg, image/png" />
                            </Box>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};
