import LoadingButton from '@mui/lab/LoadingButton';
import { Container, Divider, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';

export default ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);
    const { values, errors, handleChange, handleSubmit, handleBlur, handleReset, isSubmitting, setValues } = useFormik({
        initialValues: {
            refer_name: '',
            refer_parental: '',
            refer_email: '',
            refer_phone: '',
            refer_details: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`/professional/${data.id}/refer`, values);
                enqueueSnackbar('Dados alterados');
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false)
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            if(Boolean(data.Professional)){
                const { Professional } = data;
                const { refer_name, refer_parental, refer_email, refer_phone, refer_details } = Professional;
                setValues({ refer_name, refer_parental, refer_email, refer_phone, refer_details }, false);
            }
        }
    }, [data]);

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Nome"
                            type="text"
                            name="refer_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.refer_name}
                            error={errors.refer_name}
                            helperText={errors.refer_name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Vinculo"
                            type="text"
                            name="refer_parental"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.refer_parental}
                            error={errors.refer_parental}
                            helperText={errors.refer_parental}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Email"
                            type="text"
                            name="refer_email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.refer_email}
                            error={errors.refer_email}
                            helperText={errors.refer_email}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Telefone"
                            type="text"
                            name="refer_phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.refer_phone}
                            error={errors.refer_phone}
                            helperText={errors.refer_phone}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Detalhes"
                            type="text"
                            name="refer_details"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.refer_details}
                            error={errors.refer_details}
                            helperText={errors.refer_details}
                        />
                    </Grid>

                    <Divider />
                    <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid>
                            <LoadingButton loading={isSubmitting} onClick={handleSubmit} variant="contained">
                                Salvar dados
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
