import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './modules/Auth/context/AuthContext';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';

const myTheme = createTheme({
    typography: {
        subtitle2: {
            fontWeight: 'bold',
        },
    },
    components: {
        MuiDialogContent: {
            styleOverrides: {
                root: ()=>({
                    backgroundColor: '#eee',
                })
            }
        },
        MuiCard:{
            styleOverrides:{
                root: ({})=>({
                    border: '1px solid #eee',
                    borderRadius: 8,
                     
                })
            }
        }
    },
    overrides: {
        MUIRichTextEditor: {
            root: {
                border: '1px solid #ccc',
                borderRadius: '4px',
            },
            editor: {
                borderTop: '1px solid #ccc',
                paddingLeft: '30px',
            },
            placeHolder: {
                fontSize: '16px',
                paddingLeft: '20px',
                position: 'inherit',
            },
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={myTheme}>
            <BrowserRouter basename="/">
                <SnackbarProvider>
                    <AppProvider>
                        <AuthProvider>
                            <ConfirmProvider>
                                <Routes />
                            </ConfirmProvider>
                        </AuthProvider>
                    </AppProvider>
                </SnackbarProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
