import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import LoadingContent from '../../../components/LoadingContent';
import { useFetch } from '../../../hooks/useFetch';
import AddressForm from './AddressForm';
import BusinessForm from './BusinessForm';
import ProfileForm from './ProfileForm';
import ReferForm from './ReferForm';
import VehicleForm from './VehicleForm';

export default function ProfessionaTabs({activeTab}) {

    const { id } = useParams();
    const { data, error, mutate, isFetching } = useFetch(`/professionals/${id}`);

    if (!data) return <LoadingContent label="Carregando dados..." />;

    return (
        <TabsContainer>
            <TabBody activeBody={activeTab === 0}>
                <ProfileForm data={data} mutate={mutate}/>
            </TabBody>
            <TabBody activeBody={activeTab === 1}>
                 <BusinessForm data={data} />
            </TabBody>
            <TabBody activeBody={activeTab === 2}>
                 <AddressForm data={data} />
            </TabBody>
            <TabBody activeBody={activeTab === 3}>
                 <ReferForm data={data} />
            </TabBody>
            <TabBody activeBody={activeTab === 4}>
                 <VehicleForm data={data} />
            </TabBody>
        </TabsContainer>
    );
}

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
