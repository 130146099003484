import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import { Container } from '@mui/material';
import CustomerTabs from '../components/CustomerTabs';

export default function CustomerDetails() {
    const [activeTab, setActiveTab] = useState(0);
    const { id } = useParams();

    return (
        <Container fixed>
            <Tabs value={activeTab} onChange={(evt, activedTab) => setActiveTab(activedTab)}>
                <Tab label="Dados do cliente" />
                <Tab label="Endereço" />
                <Tab label="Tabela de negociação" />
                <Tab label="Pedidos" />
            </Tabs>
            <CustomerTabs activeTab={activeTab} />
             
        </Container>
    );
}

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
