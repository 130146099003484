import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconFile from '@mui/icons-material/PictureAsPdf';
import { useFormik } from 'formik';
import server from '../../../global/server';
import { useSnackbar } from 'notistack';
import useHandleErrors from '../../../hooks/useHandleErrors';
import IconPhoto from '@mui/icons-material/CameraAlt';
export default ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);
    const { values, errors, handleChange, handleSubmit, handleBlur, setFieldValue, isSubmitting, setValues } = useFormik({
        initialValues: {
            owner_id: '',
            brand: '',
            model: '',
            license_plate: '',
            year: '',
            color: '',
            document_vehicle: '',
            size: '',
            document_validity: '',
            photo_vehicle: '',
            photo_vehicle_left: '',
            photo_vehicle_right: '',
            photo_vehicle_back: '',
            status: false,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`/vehicle/${values.id}`, values);
                setSubmitting(false);
                enqueueSnackbar('Dados alterados');
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            if(Boolean(data.Professional)){
                const { Vehicle } = data.Professional;
                setValues({ ...Vehicle }, false);
            }
        }
    }, [data]);

    const [dialog, setDialog] = useState(false);
    const [dialogPhoto, setDialogPhoto] = useState();
    const handleImage = (event, name) => {
        const image = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            setFieldValue(name, reader.result); //
        };
        reader.onerror = e => console.error(e);
    };

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Stack direction="row" rowGap={3} spacing={2} useFlexGap flexWrap="wrap" mb={2}>
                            <CardMedia onClick={e=>setDialogPhoto(values.photo_vehicle)} component="img" sx={{ width: 100, height: 100 }} image={values.photo_vehicle} alt="picture_vehicle" />
                            <CardMedia onClick={e=>setDialogPhoto(values.photo_vehicle_left)} component="img" sx={{ width: 100, height: 100 }} image={values.photo_vehicle_left} alt="" />
                        </Stack>
                        <Stack direction="row" rowGap={3} spacing={2} useFlexGap flexWrap="wrap">
                            <CardMedia onClick={e=>setDialogPhoto(values.photo_vehicle_right)} component="img" sx={{ width: 100, height: 100 }} image={values.photo_vehicle_right} alt="" />
                            <CardMedia onClick={e=>setDialogPhoto(values.photo_vehicle_back)} component="img" sx={{ width: 100, height: 100 }} image={values.photo_vehicle_back} alt="" />
                        </Stack>
                    </Grid>
                    <Grid item container direction="row" spacing={2} xs>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Marca"
                                type="text"
                                name="brand"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.brand}
                                error={errors.brand}
                                helperText={errors.brand}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Modelo"
                                type="text"
                                name="model"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.model}
                                error={errors.model}
                                helperText={errors.model}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Cor"
                                type="text"
                                name="color"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.color}
                                error={errors.color}
                                helperText={errors.color}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Ano"
                                type="text"
                                name="color"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.year}
                                error={errors.year}
                                helperText={errors.year}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Placa"
                                type="text"
                                name="color"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.license_plate}
                                error={errors.license_plate}
                                helperText={errors.license_plate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="size">Tamanho </InputLabel>
                                <Select labelId="size" id="size" name="size" value={values.size} label="Tamanho" onChange={handleChange}>
                                    <MenuItem value="">-- --</MenuItem>
                                    <MenuItem value="1">Pequeno</MenuItem>
                                    <MenuItem value="2">Médio</MenuItem>
                                    <MenuItem value="3">Grande</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Divider />
                        <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item xs={4}>
                                <Button endIcon={<IconFile />} variant="text" onClick={() => setDialog(true)}>
                                    Documento do veículo
                                </Button>
                            </Grid>
                            <Grid>
                                <LoadingButton onClick={handleSubmit} loading={isSubmitting} variant="contained">
                                    Salvar dados
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={dialog} fullWidth maxWidth="sm" onClose={() => setDialog(false)}>
                    <DialogTitle>Visualização - Documento veículo</DialogTitle>

                    <DialogContent>
                        <img width="100%" height="100%" src={values.document_vehicle} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" startIcon={<IconPhoto />} component="label" onChange={e => handleImage(e, 'document_vehicle')}>
                            <Box name="document_vehicle" value={values.document_vehicle} onChange={handleChange}>
                                Inserir documento˝
                                <input type="file" hidden accept="image/jpeg, image/jpg, image/png" />
                            </Box>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={dialogPhoto} fullWidth maxWidth="sm" onClose={() => setDialogPhoto()}>
                    <DialogTitle>Visualização - Documento veículo</DialogTitle>

                    <DialogContent>
                        <img width="100%" height="100%" src={dialogPhoto} />
                    </DialogContent>
                    {/* <DialogActions>
                        <Button variant="contained" startIcon={<IconPhoto />} component="label" onChange={e => handleImage(e, 'document_vehicle')}>
                            <Box name="document_vehicle" value={values.document_vehicle} onChange={handleChange}>
                                Inserir documento˝
                                <input type="file" hidden accept="image/jpeg, image/jpg, image/png" />
                            </Box>
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </Container>
        </>
    );
};
