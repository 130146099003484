import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import { Container } from '@mui/material';
import UserTabs from '../components/UserTabs';

export default function UsersDetails() {
    const [activeTab, setActiveTab] = useState(0);
    const { id } = useParams();

    return (
        <Container fixed>
            <Tabs value={activeTab} onChange={(evt, activedTab) => setActiveTab(activedTab)}>
                <Tab label="Dados do usuário" />
                {/* <Tab label="Endereço" /> */}
            </Tabs>
            <UserTabs activeTab={activeTab} />
        </Container>
    );
}

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
