import {
    Avatar,
    Box,
    Button,
    Container,
    Dialog,
    Divider,
    Drawer,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import OrdersDetails from '../../Dashboard/screens/OrdersDetails';
import CloseIcon from '@mui/icons-material/Close';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import Route from '@mui/icons-material/Route';
import size from '../../../global/size';
import { useFormik } from 'formik';
import { useParams, useSearchParams } from 'react-router-dom';
import lodash from 'lodash';
import { status } from '../../../global/helpers';
import gregorian_pt from '../../../global/gregorian_pt';
import DatePicker from 'react-multi-date-picker';
import Visibility from '@mui/icons-material/Visibility';
import useHandleErrors from '../../../hooks/useHandleErrors';
import server from '../../../global/server';
export default props => {
    const [filterView, setFilterView] = useState(false);
    const [orderId, setOrderId] = useState();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams({ status: 'CONCLUDED', owner_id: id });
    const { handleChange, handleSubmit, values, setValues, setFieldValue } = useFormik({
        initialValues: {
            id: '',
            status: '',
            origin: '',
            destination: '',
            scheduled: '',
            concludedAt: '',
            document: '',
            email: '',
        },
        onSubmit: values => {
            setSearchParams({ ...lodash.omitBy(values, v => v === ''), status: values.status, owner_id: id });
        },
    });

    const PickerDate = ({ openCalendar, value, separator, name, label, ...props }) => {
        value = value.split(separator);

        const viewValue = !Boolean(value[0])
            ? undefined
            : value.length === 1
            ? new Date(value[0]).toLocaleDateString()
            : value.length === 2
            ? `De ${new Date(value[0]).toLocaleDateString()} a ${new Date(value[1]).toLocaleDateString()}`
            : null;

        return (
            <TextField
                placeholder="Selecione uma ou mais datas"
                fullWidth
                value={viewValue}
                label={label}
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="end" sx={{ display: viewValue ? 'inherit' : 'none' }}>
                                <IconButton onClick={() => setFieldValue(name, '')}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                            <InputAdornment position="start">
                                <IconButton onClick={openCalendar}>
                                    <CalendarIcon />
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />
        );
    };

    useEffect(() => {
        if (searchParams) {
            let toFields = Object.fromEntries(searchParams);
            toFields.concludedAt = searchParams.getAll('concludedAt') || undefined;
            setValues(toFields, false);
        }
    }, [searchParams]);

    const { data, error, mutate, isFetching } = useFetch('/delivery', {
        params: searchParams,
    });

    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID', width: 50 },
        // {
        //     field: 'owner',
        //     headerName: 'Cliente',
        //     flex: 1,
        //     valueGetter: ({ value }) => value.name
        // },
        {
            field: 'scheduled',
            headerName: 'Agendamento',
            type: 'date',
            width: 120,
            valueGetter: params => `${params.row.scheduled ? new Date(params.row.scheduled).toLocaleDateString('pt-BR') : 'Imediato'}`,
        },

        // {
        //     field: 'origin',
        //     headerName: 'Origem',
        //     width: 200,

        //     valueGetter: params => `${params.row.origin_district || ''}${', ' + params.row.origin_city || ''}`,
        // },
        // {
        //     field: 'destination',
        //     headerName: 'Destino',
        //     width: 200,
        //     valueGetter: params => `${params.row.destination_district || ''}${', ' + params.row.destination_city || ''}`,

        //     //editable: true,
        // },
        {
            field: 'route',
            headerName: 'Trajeto',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            //valueGetter: params => `${params.row.origin_district || ''}${', ' + params.row.origin_city || ''}`,
            renderCell: ({ row }) => (
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%' }} gap={2}>
                    <Stack textAlign="center">
                        <Typography fontSize={11}>{`${row.origin_district || ''} `}</Typography>{' '}
                        <Typography fontSize={11}>{`${row.origin_city || ''}`}</Typography>
                    </Stack>
                    <Route />
                    <Stack textAlign="center">
                        <Typography fontSize={11}>{`${row.destination_district || ''}`}</Typography>{' '}
                        <Typography fontSize={11}>{`${row.destination_city || ''}`}</Typography>
                    </Stack>
                </Stack>
            ),
        },
        {
            field: 'deliver',
            headerName: 'Motorista',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) =>
                Boolean(row.DeliveryOrders.length) ? (
                    <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
                        <Avatar sx={{ width: 30, height: 30 }} src={row.DeliveryOrders[0].User.picture} />{' '}
                        {row.DeliveryOrders[0].User.name.split(' ', 2).join(' ')}
                    </Stack>
                ) : (
                    '-'
                ),
        },
        {
            field: 'size',
            headerName: 'Tamanho',
            width: 90,
            valueGetter: params => size(params.value),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            valueGetter: ({ value }) => status[value],
        },
        {
            field: 'concludedAt',
            headerName: 'Data da conclusao',
            width: 130,
            valueGetter: ({ row }) => {
                const concludedAt = row.DeliveryOrders[0]?.DeliveryFlowHistories.filter(item => item.history === 'DELIVERED')[0];
                return new Date(concludedAt?.createdAt).toLocaleString('pt-BR', { timeStyle: 'short', dateStyle: 'short' }) || '-';
            },
        },
        {
            field: 'amount',
            headerName: 'Valor',
            valueGetter: params => `${Number(params.row.amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) || '--'}`,
            width: 90,
        },
        {
            field: 'actions',
            //headerName: 'actions',
            type: 'actions',
            width: 30,
            renderCell: params => (
                <IconButton onClick={() => setOrderId(params.row.id)} size="small">
                    <Visibility />
                </IconButton>
            ),
        },
    ]);

    const download = async () => {
        try {
            const { data } = await server.get('delivery', {
                params: {
                    ...Object.fromEntries(searchParams),
                    download: true,
                },
                headers: {
                    'Content-Type': 'application/pdf',
                },
                responseType: 'blob',
                timeout: 10000,
            });
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([data]));
            link.href = url;
            link.setAttribute('download', `Relatório ${new Date()}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            setErrs(error);
        }
    };

    if (error) return null;

    return (
        <>
            <Drawer anchor="right" variant="temporary" open={filterView} onClose={e => setFilterView(false)}>
                <Toolbar>
                    <Typography variant="h6">Filtros</Typography>
                    <Divider />
                </Toolbar>
                <Box sx={{ width: 350, p: 3 }}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="ID do Pedido"
                                name="id"
                                value={values.id}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setOrigin()}>
                                //             {origin && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel id="edducation">Status</InputLabel>
                                <Select labelId="edducation" id="status" name="status" value={values.status} label="Status" onChange={handleChange}>
                                    <MenuItem value="">TODOS</MenuItem>
                                    <MenuItem value="DRAFT">{status['DRAFT']}</MenuItem>
                                    <MenuItem value="ANALYSE">{status['ANALYSE']}</MenuItem>
                                    <MenuItem value="WAITING">{status['WAITING']}</MenuItem>
                                    <MenuItem value="IN_PROGRESS">{status['IN_PROGRESS']}</MenuItem>
                                    <MenuItem value="CONCLUDED">{status['CONCLUDED']}</MenuItem>
                                    <MenuItem value="PAYMENT_FAIL">{status['PAYMENT_FAIL']}</MenuItem>
                                    <MenuItem value="DISAPPROVED">{status['DISAPPROVED']}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            {/* <TextField fullWidth type="date" name="scheduled" value={values.scheduled} onChange={handleChange} /> */}
                            <DatePicker
                                containerStyle={{ width: '100%' }}
                                onChange={e => setFieldValue('concludedAt', e)}
                                type="date"
                                range
                                locale={gregorian_pt}
                                format="MM/DD/YYYY"
                                // style={{ height: '3.5rem', width: '100%', fontSize: '1rem', fontWeight: '700' }}
                                // containerStyle={{ width: '100%' }}
                                // placeholder="Coloque as datas referentes aos serviços aqui"
                                //minDate={new Date()}
                                value={values.concludedAt}
                                dateSeparator=" a "
                                required
                                render={<PickerDate name="concludedAt" label="Data da conclusão" />}
                            />
                        </Grid>

                        {/* <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="Origem"
                                name="origin"
                                value={values.origin}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setOrigin()}>
                                //             {origin && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                type="text"
                                label="Destino"
                                name="destination"
                                value={values.destination}
                                onChange={handleChange}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton aria-label="clean text" onClick={e => setDestination()}>
                                //             {destination && <CloseIcon />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                        </Grid> */}

                        <Grid item xs>
                            <Button variant="contained" onClick={handleSubmit}>
                                Aplicar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
            <Container maxWidth="xl" fixed sx={{ py: 2 }}>
                <Stack direction="row" justifyContent="space-between" mb={1}>
                    <Typography variant="h5" component="h2">
                        Pedidos
                    </Typography>
                    <Stack direction="row" gap={2}>
                        <Button variant="contained" onClick={e => download()}>
                            Baixar
                        </Button>
                        <Button variant="contained" onClick={e => setFilterView(true)}>
                            Filtros
                        </Button>
                    </Stack>
                </Stack>
                <Grid container direction="row" alignItems="center" justifyContent="flex-end" sx={{ marginBottom: 2 }}>
                    <Grid item xs>
                        {/* <Stack direction="row"  alignItems="flex-end" justifyContent="flex-end" spacing={1}>
                            {Object.entries(values).map(item => {
                                return (
                                <Chip variant="outlined" color="primary" label={`${item[0]}: ${status[item[1]]}`} />
                            )})}
                        </Stack> */}
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'right' }}></Grid>
                </Grid>

                <Grid container>
                    <DataGrid
                        autoHeight
                        //paginationMode="server"
                        loading={isFetching}
                        rows={data?.rows || []}
                        columns={columns}
                        //rowsPerPageOptions={[10, 25, 50, 100]}
                    />
                </Grid>
            </Container>
            <Dialog fullWidth fullScreen maxWidth="xl" open={Boolean(orderId)} scroll="paper">
                {Boolean(orderId) && <OrdersDetails mutateList={mutate} handleClose={() => setOrderId()} id={orderId} />}
            </Dialog>
        </>
    );
};
