import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import BlockIcon from '@mui/icons-material/Block';
export default props => {
    const navigation = useNavigate()
    return (
        <ContainerLoading>
            <div>
                <BlockIcon />
            </div>
            <div>{props.label && <LoadingText variant='subtitle2'>{props.label}</LoadingText>}</div>
            {/* <Button onClick={()=>navigation(-1) }>Voltar</Button> */}
        </ContainerLoading>
    );
};

const ContainerLoading = styled.div`
    padding: 100px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:100%;
`;

const LoadingText = styled(Typography)`
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
    display: block;
`;
