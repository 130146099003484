import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import LoadingContent from '../../../components/LoadingContent';
import { useFetch } from '../../../hooks/useFetch';
import AddressForm from './AddressForm';
import ProfileForm from './ProfileForm';

export default function UserTabs({ activeTab }) {
    const { id } = useParams();
    const { data, error, mutate, isFetching } = useFetch(`/users/${id}`);

    if (!data) return <LoadingContent label="Carregando dados..." />;

    return (
        <TabsContainer>
            <TabBody activeBody={activeTab === 0}>
                <ProfileForm data={data} mutate={mutate} />
            </TabBody>

            {/* <TabBody activeBody={activeTab === 1}>
                <AddressForm data={data} mutate={mutate}/>
            </TabBody> */}
        </TabsContainer>
    );
}

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
