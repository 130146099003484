import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Box,
    Button,
    Card,
    CardMedia,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconFile from '@mui/icons-material/FileDownload';
import IconPhoto from '@mui/icons-material/CameraAlt';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import server from '../../../global/server';
import { useSnackbar } from 'notistack';
import useHandleErrors from '../../../hooks/useHandleErrors';
import ActivateProfessional from './ActivateProfessional';
import banks from '../../../global/banks';

export default ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);
    const { values, errors, handleChange, handleSubmit, handleBlur, setFieldValue, isSubmitting, setValues } = useFormik({
        initialValues: {
            business_document: '',
            business_name: '',
            bank: '',
            branch_number: '',
            branch_check_digit: '',
            account_number: '',
            account_check_digit: '',
            account_type: '',
            bank_file: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`/professional/${data.id}`, values);
                enqueueSnackbar('Dados alterados');
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {

            if(Boolean(data.Professional)){
                const {
                    business_document,
                    business_name,
                    status,
                    bank,
                    branch_number,
                    branch_check_digit,
                    account_number,
                    account_check_digit,
                    account_type,
                    bank_file,
                } = data.Professional;
                setValues(
                    {
                        business_document,
                        business_name,
                        status,
                        bank,
                        branch_number,
                        branch_check_digit,
                        account_number,
                        account_check_digit,
                        account_type,
                        bank_file,
                    },
                    false,
                );
            }
            
        }
    }, [data]);

    const [dialog, setDialog] = useState(false);
    const handleImage = (event, name) => {
        const image = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            setFieldValue(name, reader.result); //
        };
        reader.onerror = e => console.error(e);
    };

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            Caso haja alteração no CNPJ e/ou Razão Social do credenciado, a mesma{' '}
                            <strong> também deve ser realizada diretamente </strong>pelo painel da Pagar.me. <br />A razão social da empresa e o
                            titular da conta bancária devem ser o mesmo.
                        </Alert>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {Boolean(data?.Professional?.recipient_id) && <Alert severity="success">Integrada ao Pagar.me </Alert>}
                        {!Boolean(data?.Professional?.recipient_id) && <Alert severity="warning">Não integrada ao Pagar.me </Alert>}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="CNPJ"
                            required
                            type="text"
                            name="business_document"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.business_document}
                            error={errors.business_document}
                            helperText={errors.business_document}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Razao Social"
                            type="text"
                            name="business_name"
                            inputProps={{
                                maxLength: 30,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.business_name}
                            error={errors.business_name}
                            helperText={errors.business_name}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="banco">Banco{values?.bank}</InputLabel>
                            <Select
                                labelId="banco"
                                id="banco-select"
                                name="bank"
                                value={values?.bank}
                                label="Banco"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            >
                                {banks.map(item => (
                                    <MenuItem value={item.id}>
                                        {item.id} - {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.bank}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Agencia"
                            type="text"
                            name="branch_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.branch_number}
                            error={errors.branch_number}
                            helperText={errors.branch_number}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Digito"
                            type="text"
                            name="branch_check_digit"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.branch_check_digit}
                            error={errors.branch_check_digit}
                            helperText={errors.branch_check_digit}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Conta"
                            type="text"
                            name="account_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.account_number}
                            error={errors.account_number}
                            helperText={errors.account_number}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Digito"
                            type="text"
                            name="account_check_digit"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.account_check_digit}
                            error={errors.account_check_digit}
                            helperText={errors.account_check_digit}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tipo da Conta</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="account_type"
                                value={values?.account_type}
                                label="Tipo da conta"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            >
                                <MenuItem value="checking">Corrente</MenuItem>
                                <MenuItem value="saving">Poupança</MenuItem>
                            </Select>
                            <FormHelperText>{errors.account_type}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs direction="row">
                        <Stack direction="row" spacing={2}>
                            <LoadingButton loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit} variant="contained">
                                Salvar dados
                            </LoadingButton>
                            <Button endIcon={<IconFile />} onClick={() => setDialog(true)} variant="text">
                                Comprovante bancário
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Dialog open={dialog} fullWidth maxWidth="md" onClose={() => setDialog(false)}>
                    <DialogTitle>Comprovante bancário</DialogTitle>

                    <DialogContent>
                        <Stack direction="row" spacing={2}>
                            <Box>
                                <img style={{ maxWidth: '100%' }} src={values.bank_file} />
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" startIcon={<IconPhoto />} component="label" onChange={e => handleImage(e, 'bank_file')}>
                            <Box name="bank_file" value={values.bank_file} onChange={handleChange}>
                                Inserir comprovante bancário
                                <input type="file" hidden accept="image/jpeg, image/jpg, image/png" />
                            </Box>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};
