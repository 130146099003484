import {
    Alert,
    AppBar,
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    Toolbar,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import LoadingContent from '../../../components/LoadingContent';
import { useFetch } from '../../../hooks/useFetch';
import CardProfessional from './CardProfessional';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar } from 'notistack';
import server from '../../../global/server';
import ErrorContent from '../../../components/ErrorContent';
import size from '../../../global/size';
import { status } from '../../../global/helpers';
import PaymentDetails from './PaymentDetails';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { KeyboardReturn, LocalShipping, Map, MapOutlined, MoreVert, Photo, Route, Timelapse, PhotoAlbum, Check } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import RoadmapTable from './RoadmapTable';

export default ({ id, handleClose, mutateList }) => {
    const { data, error, mutate, isLoading } = useFetch(`/delivery/${id}`);
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);
    const [openJSON, setOpenJSON] = useState(false);
    const [viewImage, setViewImage] = useState();
    const handleAnalyse = async status => {
        try {
            setSubmitting(true);
            const response = await server.post(`/packages/${id}/analyse`, { status });
            enqueueSnackbar('Ordem alterada', { variant: 'success' });
            mutate();
            mutateList();
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            enqueueSnackbar('Erro ao alterar', { variant: 'error' });
        }
    };

    if (isLoading) return <LoadingContent label="Carregando dados..." />;
    if (error) return <ErrorContent label="Erro ao buscar dados da ordem. Tente mais tarde" />;

    const { owner, DeliveryOrders, Ratings, PaymentOrder, ...order } = data;

    console.log('Roadmap', data.roadmap);

    return (
        <>
            <DialogTitle sx={{ margin: 0, padding: 0 }}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Detalhes da Ordem #{data.id}
                        </Typography>
                        {order.status === 'ANALYSE' && (
                            <>
                                <Button
                                    sx={{ color: '#fff', marginRight: 3 }}
                                    variant="outlined"
                                    edge="end"
                                    color="inherit"
                                    onClick={() => handleAnalyse('WAITING')}
                                    aria-label="close"
                                >
                                    Aprovar <CheckIcon />
                                </Button>
                                <Button
                                    sx={{ color: '#fff', marginRight: 3 }}
                                    variant="outlined"
                                    edge="end"
                                    color="inherit"
                                    onClick={() => handleAnalyse('DISAPPROVED')}
                                    aria-label="close"
                                >
                                    Rejeitar <CloseIcon />
                                </Button>
                            </>
                        )}
                        <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardHeader avatar={<Avatar src={owner.picture} />} title="Solicitante" subheader={owner.name} />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Detalhes </Typography>
                                        <Typography>{order.details || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Tamanho do pacote </Typography>
                                        <Typography>{size(order.size) || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Altura</Typography>
                                        <Typography>{order.height || '--'}cm</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Largura </Typography>
                                        <Typography>{order.width || '--'}cm</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Compr. </Typography>
                                        <Typography>{order.length || '--'} cm</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Peso </Typography>
                                        <Typography>{order.weight || '--'}g</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Pagamento </Typography>
                                        <Typography>{Boolean(PaymentOrder) ? `Pagamento configurado: ${PaymentOrder.method}`: 'Sem forma de pagamento'}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Typography sx={{ fontSize: 12, fontStyle: 'italic' }} variant="body1">
                                    Criada em {Boolean(order.createdAt) && new Date(order.createdAt).toLocaleString()}. Alterada em{' '}
                                    {Boolean(order.updatedAt) && new Date(order.updatedAt).toLocaleString()}
                                </Typography>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardHeader
                                avatar={<LocalShipping />}
                                title="Sobre a entrega"
                                subheader={<Chip size="small" label={status[order.status] || '--'} />}
                                action={
                                    <IconButton disabled={!(order.status === 'PAYMENT_FAIL')} onClick={e => setOpenJSON(true)}>
                                        <MoreVert />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Origem</Typography>
                                        <Typography>
                                            {order.origin_zipcode}, {order.origin_address}, {order.origin_district}, {order.origin_state}-
                                            {order.origin_city}, {order.origin_refer}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Destino</Typography>
                                        <Typography>
                                            {order.destination_zipcode}, {order.destination_address}, {order.destination_district},{' '}
                                            {order.destination_state}-{order.destination_city}, {order.destination_refer}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Distância </Typography>
                                        <Typography>
                                            {Number(order.distance).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) || '--'} Km
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Duração (tempo) </Typography>
                                        <Typography>Aprox. {Number(order.duration).toFixed(0) || '--'} min</Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Valor </Typography>
                                        <Typography>
                                            R${' '}
                                            {Number(order.amount).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ||
                                                '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Pedágio </Typography>
                                        <Typography>
                                            R${' '}
                                            {Number(order.toll_tax).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ||
                                                '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Valor Líquido </Typography>
                                        <Typography>
                                            R${' '}
                                            {Number(order.net_amount).toLocaleString('pt-BR', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            }) || '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Taxa de retorno </Typography>
                                        <Typography>
                                            R${' '}
                                            {Number(order.reverse_tax).toLocaleString('pt-BR', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            }) || '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Pedágio Taxa de retorno </Typography>
                                        <Typography>
                                            R${' '}
                                            {Number(order.reverse_toll_tax).toLocaleString('pt-BR', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            }) || '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2">Agendamento </Typography>
                                        <Typography>{order.scheduled || 'Imediato'}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {!Boolean(DeliveryOrders.length) && (
                            <Grid item xs={12}>
                                <Alert severity="warning">Pacote aguardando aceite.</Alert>
                            </Grid>
                        )}
                        {Boolean(DeliveryOrders.length) && <CardProfessional DeliveryOrder={DeliveryOrders[0]} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Trajeto planejado" avatar={<Route />} />
                            <CardContent>
                                <RoadmapTable roadmap={data.roadmap || []} />
                                {/* <DataGrid
                                    autoHeight
                                    rows={data.roadmap || []}
                                    density="compact"
                                    hideFooter
                                    columns={[
                                        {
                                            field: 'order',
                                            headerName: '#',
                                            width: 30,
                                            valueGetter: ({ value }) => value + 1,
                                        },
                                        {
                                            field: 'input_address',
                                            headerName: 'Endereço',
                                            flex: 1,
                                            minWidth:250,
                                            renderCell: ({value})=> <div><Typography>{value}</Typography></div>
                                        },
                                        {
                                            field: 'complement',
                                            headerName: 'Complemento',
                                            flex: 1,
                                            wrapText: true
                                        },
                                        {
                                            field: 'details',
                                            headerName: 'Observações',
                                            flex: 1,
                                            wrapText: true
                                        },
                                        {
                                            field: 'updatedAt',
                                            headerName: 'Concluído em',
                                            width:150,
                                            renderCell: ({ value, row}) =>
                                                row.status === 'CONCLUDED' && new Date(value).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' }) || '-',
                                        },
                                        {
                                            field: 'geolocation',
                                            headerName: 'Localização',
                                            renderCell: ({ value }) => (
                                                <IconButton
                                                    disabled={!Boolean(value)}
                                                    target="_blank"
                                                    href={`https://www.google.com/maps/search/?api=1&query=${value}`}
                                                >
                                                    <Map />
                                                </IconButton>
                                            ),
                                        },
                                        {
                                            field: 'delivery_image',
                                            headerName: 'Foto da entrega',
                                            renderCell: ({ value }) => (
                                                <IconButton disabled={!Boolean(value)} onClick={e => setViewImage(value)}>
                                                    <Photo />
                                                </IconButton>
                                            ),
                                        },
                                        {
                                            field: 'delivery_document',
                                            headerName: 'Documento',
                                            renderCell: ({ value }) => (
                                                <IconButton disabled={!Boolean(value)} onClick={e => setViewImage(value)}>
                                                    <PhotoAlbum />
                                                </IconButton>
                                            ),
                                        },
                                        {
                                            field: 'reverse',
                                            headerName: 'Rerverso',
                                            width: 120,
                                            valueGetter: ({ value }) => (value === true ? 'Rerverso' : ' - '),
                                            renderCell: ({ value }) =>
                                                value === 'Rerverso' && (
                                                    <Chip size="small" color="warning" icon={<KeyboardReturn />} label="Reverso" />
                                                ),
                                        },
                                        {
                                            field: 'status',
                                            headerName: 'Situação',
                                            width: 130,

                                            valueGetter: ({ value }) =>
                                                !Boolean(value) ? 'Aguardando' : value === 'EMPTY' ? 'Não realizado' : 'Concluído',
                                            renderCell: ({ value }) =>
                                                value === 'Concluído' ? (
                                                    <Chip icon={<Check />} color="success" size="small" label={value} />
                                                ) : value === 'Aguardando' ? (
                                                    <Chip icon={<Timelapse />} color="info" size="small" label={value} />
                                                ) : (
                                                    <Chip icon={<Timelapse />} color="error" size="small" label={value} />
                                                ),
                                        },
                                    ]}
                                /> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={4}>
                    <Grid container item xs={8} spacing={2}>
                        {Boolean(DeliveryOrders.length) && Boolean(DeliveryOrders[0].order_id) && <PaymentDetails order={DeliveryOrders[0]} />}
                    </Grid>
                    
                </Grid> */}

                {Boolean(PaymentOrder) && (
                    <Dialog maxWidth="lg" onClose={e => setOpenJSON(false)} open={openJSON}>
                        <DialogContent>
                            <JSONPretty data={PaymentOrder.message} />
                        </DialogContent>
                    </Dialog>
                )}

                <Dialog maxWidth="md" open={Boolean(viewImage)} onClose={e => setViewImage()}>
                    <DialogContent>
                        <Box component="img" src={viewImage} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => setViewImage()}>Fechar</Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
        </>
    );
};
