import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../modules/Auth/context/AuthContext';
import Container from './Container';
import Authentication from './Authentication';

export const ProtectedLayout = () => {
    const { logged } = useAuth();
    if (!logged) {
        return <Authentication />;
    }
    return (
        <Container>
            <Outlet />
        </Container>
    );
};
