import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export default props => {
    return (
        <ContainerLoading>
            <div>
                <CircularProgress />
            </div>
            <div>{props.label && <LoadingText variant="subititle2">{props.label}</LoadingText>}</div>
        </ContainerLoading>
    );
};

const ContainerLoading = styled.div`
    padding: 100px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:100%;
`;

const LoadingText = styled(Typography)`
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
    display: block;
`;
