import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';

export default ({ account_id, status, mutate }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { values, handleSubmit, isSubmitting } = useFormik({
        initialValues: {},
        onSubmit: async () => {
            try {
                if (status) {
                    await server.put(`/professional/${account_id}/inactivate`);
                    await mutate(); 
                    enqueueSnackbar('Conta desativada');
                } else {
                    await server.put(`/professional/${account_id}/activate`);
                    await mutate(); 
                    enqueueSnackbar('Conta liberada');
                }
                
                
            } catch (error) {
                setErrs(error);
            }
        },
    });

    return (
        <>
            {status && (
                <LoadingButton loading={isSubmitting} onClick={handleSubmit} variant="contained" color="error">
                    Desativar conta
                </LoadingButton>
            )}
            {!status && (
                <LoadingButton loading={isSubmitting} onClick={handleSubmit} variant="contained" color="success">
                    Ativar conta
                </LoadingButton>
            )}
        </>
    );
};
