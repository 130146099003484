import { useMemo, useState } from 'react';
import { useMantineReactTable, MantineReactTable } from 'mantine-react-table';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Chip } from '@mui/material';
import { Check, KeyboardReturn, Map, Photo, PhotoAlbum, Timelapse } from '@mui/icons-material';

export default ({ roadmap }) => {
    const [viewImage, setViewImage] = useState();
    const columns = useMemo(() => [
        {
            accessorKey: 'order',
            header: '#',
            maxSize: 40,
            // valueGetter: ({ value }) => value + 1,
        },
        {
            accessorKey: 'input_address',
            header: 'Endereço',
            flex: 1,
            minSize: 250,
        },
        {
            accessorKey: 'complement',
            header: 'Complemento',
            flex: 1,
        },
        {
            accessorKey: 'details',
            header: 'Observações',
            flex: 1,
        },
        {
            accessorKey: 'updatedAt',
            header: 'Concluído em',
            width: 150,
            Cell: ({ cell, row }) =>
                ( new Date(cell.getValue() )?.toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' })) || '-',
        },
        {
            accessorKey: 'geolocation',
            header: 'Localização',
            Cell: ({ cell }) => (
                <IconButton disabled={!Boolean(cell.getValue())} target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${cell.getValue()}`}>
                    <Map />
                </IconButton>
            ),
        },
        {
            accessorKey: 'delivery_image',
            header: 'Foto da entrega',
            Cell: ({ cell }) => (
                <IconButton disabled={!Boolean(cell.getValue())} onClick={e => setViewImage(cell.getValue()) }>
                    <Photo />
                </IconButton>
            ),
        },
        {
            accessorKey: 'delivery_document',
            header: 'Documento',
            Cell: ({ cell }) => (
                <IconButton disabled={!Boolean(cell.getValue())} onClick={e => setViewImage(cell.getValue())}>
                    <PhotoAlbum />
                </IconButton>
            ),
        },
        {
            accessorKey: 'reverse',
            header: 'Rerverso',
            width: 120,
            // valueGetter: ({ value }) => (value === true ? 'Rerverso' : ' - '),
            Cell: ({ cell }) => cell.getValue() === 'Rerverso' && <Chip size="small" color="warning" icon={<KeyboardReturn />} label="Reverso" />,
        },
        {
            accessorKey: 'status',
            header: 'Situação',
            width: 130,

            // valueGetter: ({ value }) => (!Boolean(value) ? 'Aguardando' : value === 'EMPTY' ? 'Não realizado' : 'Concluído'),
             Cell: ({ cell  }) =>
                cell.getValue() === 'CONCLUDED' ? (
                    <Chip icon={<Check />} color="success" size="small" label="Concluído" />
                ) : cell.getValue() === null ? (
                    <Chip icon={<Timelapse />} color="info" size="small" label="Aguardando" />
                ) : (
                    <Chip icon={<Timelapse />} color="error" size="small" label="Não realizado" />
                ),
        },
    ]);

    const table = useMantineReactTable({ columns, data: roadmap , enableColumnResizing: true});
    return (
        <>
            <MantineReactTable columnResizeMode="onEnd" table={table} />
            <Dialog maxWidth="md" open={Boolean(viewImage)} onClose={e => setViewImage()}>
                <DialogContent>
                    <Box component="img" src={viewImage} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={e => setViewImage()}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
