import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Container, Divider, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconFile from '@mui/icons-material/FileDownload';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useHandleErrors from '../../../hooks/useHandleErrors';
import server from '../../../global/server';
import { useSnackbar } from 'notistack';

export default ({ data }) => {
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { enqueueSnackbar } = useSnackbar();

    const { values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setValues } = useFormik({
        initialValues: {
            max_km_order: '',
            max_km_return: '',
            max_weigth_order: '',
            amount_km_big: '',
            amount_km_middle: '',
            amount_km_small: '',
            min_amount_big: '',
            min_amount_middle: '',
            min_amount_small: '',
            tax_roadmap_point_small: '',
            tax_roadmap_point_middle: '',
            tax_roadmap_point_big: '',
            view_radius: '',
            tax_service_gateway: '',
            tax_service_ff: '',
            tax_credit_card: '',
            tax_antencip: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await server.put('/config', values);
                enqueueSnackbar('Configuração alterada');
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });
    

    useEffect(() => {
        if (data) {
            setValues(data, false);
        }
    }, [data]);

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Km Max por pedido"
                            type="text"
                            name="max_km_order"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.max_km_order}
                            error={errors.max_km_order}
                            helperText={errors.max_km_order}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Km</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Peso max por pedido"
                            type="text"
                            name="max_weigth_order"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.max_weigth_order}
                            error={errors.max_weigth_order}
                            helperText={errors.max_weigth_order}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Raio de encomendas do motorista (km)"
                            type="text"
                            name="view_radius"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.view_radius}
                            error={errors.view_radius}
                            helperText={errors.view_radius}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Km</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Distancia/Taxa de retorno"
                            type="text"
                            name="max_km_return"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.max_km_return}
                            error={errors.max_km_return}
                            helperText={errors.max_km_return}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Km</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Typography variant="h6" sx={{ marginTop: 2 }}>
                            Franquia Padrão
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Valor por km/Pequeno"
                            type="text"
                            name="amount_km_small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.amount_km_small.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.amount_km_small}
                            helperText={errors.amount_km_small}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Valor por km/Medio"
                            type="text"
                            name="amount_km_middle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.amount_km_middle.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.amount_km_middle}
                            helperText={errors.amount_km_middle}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Valor por km/Grande"
                            type="text"
                            name="amount_km_big"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.amount_km_big.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.amount_km_big}
                            helperText={errors.amount_km_big}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Divider />
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Franquia/Pequeno"
                            type="text"
                            name="min_amount_small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.min_amount_small.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.min_amount_small}
                            helperText={errors.min_amount_small}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Franquia/Médio"
                            type="text"
                            name="min_amount_middle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.min_amount_middle.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.min_amount_middle}
                            helperText={errors.min_amount_middle}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Franquia/Grande"
                            type="text"
                            name="min_amount_big"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.min_amount_big.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.min_amount_big}
                            helperText={errors.min_amount_big}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                     
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Adicional por ponto/Pequeno"
                            type="text"
                            name="tax_roadmap_point_small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_roadmap_point_small.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_roadmap_point_small}
                            helperText={errors.tax_roadmap_point_small}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Adicional por ponto/Médio"
                            type="text"
                            name="tax_roadmap_point_middle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_roadmap_point_middle.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_roadmap_point_middle}
                            helperText={errors.tax_roadmap_point_middle}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Adicional por ponto/Grande"
                            type="text"
                            name="tax_roadmap_point_big"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_roadmap_point_big.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_roadmap_point_big}
                            helperText={errors.tax_roadmap_point_big}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Typography variant="h6" sx={{ marginTop: 2 }}>
                            Taxas{' '}
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Taxa de serviço Integrador (R$)"
                            type="text"
                            name="tax_service_gateway"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_service_gateway.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_service_gateway}
                            helperText={errors.tax_service_gateway}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Taxa de serviço FF (%)"
                            type="text"
                            name="tax_service_ff"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_service_ff.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_service_ff}
                            helperText={errors.tax_service_ff}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Taxa Cartão de Crédito (%)"
                            type="text"
                            name="tax_credit_card"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={Number(values.tax_credit_card).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_credit_card}
                            helperText={errors.tax_credit_card}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Taxa Antecipação (%)"
                            type="text"
                            name="tax_antencip"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_antencip.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            error={errors.tax_antencip}
                            helperText={errors.tax_antencip}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid>
                            <LoadingButton onClick={handleSubmit} loading={isSubmitting} variant="contained">
                                Salvar dados
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
