import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useNavigate } from 'react-router-dom';

export default ({ account_id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
   const navigation = useNavigate()
    useHandleErrors(errs);

    const { values, handleSubmit, isSubmitting } = useFormik({
        initialValues: {},
        onSubmit: async () => {
            try {
                await server.delete(`/professional/${account_id}`);
                enqueueSnackbar('Conta removida');
                navigation(-1)
            } catch (error) {
                console.log(error.response)
                setErrs(error);
            }
        },
    });

    return (
        <>
            <LoadingButton loading={isSubmitting} onClick={e=>window.confirm('Você realmente vai remover o profissional da base de dados?', 'Esta ação é irreversível') && handleSubmit() } color="error">
                Deletar profissional
            </LoadingButton>
        </>
    );
};
