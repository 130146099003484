export default [
    { id: '246', name: 'Banco ABC Brasil S.A.' },
    { id: '748', name: 'Banco Cooperativo Sicredi S.A.' },
    { id: '117', name: 'Advanced Cc Ltda' },
    { id: '121', name: 'Banco Agibank S.A.' },
    { id: '172', name: 'Albatross Ccv S.A' },
    { id: '188', name: 'Ativa Investimentos S.A' },
    { id: '280', name: 'Avista S.A. Crédito, Financiamento e Investimento' },
    { id: '080', name: 'B&T Cc Ltda' },
    { id: '654', name: 'Banco A.J.Renner' },
    { id: '246', name: 'Banco ABC Brasil S.A.' },
    { id: '075', name: 'Banco ABN AMRO S.A' },
    { id: '121', name: 'Banco Agibank S.A.' },
    { id: '025', name: 'Banco Alfa S.A.' },
    { id: '641', name: 'Banco Alvorada S.A.' },
    { id: '065', name: 'Banco Andbank (Brasil) S.A.' },
    { id: '213', name: 'Banco Arbi S.A.' },
    { id: '096', name: 'Banco B3 S.A.' },
    { id: '024', name: 'Banco BANDEPE S.A.' },
    { id: '318', name: 'Banco BMG S.A.' },
    { id: '752', name: 'Banco BNP Paribas Brasil S.A.' },
    { id: '107', name: 'Banco BOCOM BBM S.A.' },
    { id: '063', name: 'Banco Bradescard S.A.' },
    { id: '036', name: 'Banco Bradesco BBI S.A.' },
    { id: '122', name: 'Banco Bradesco BERJ S.A.' },
    { id: '204', name: 'Banco Bradesco Cartões S.A.' },
    { id: '394', name: 'Banco Bradesco Financiamentos S.A.' },
    { id: '237', name: 'Banco Bradesco S.A.' },
    { id: '218', name: 'Banco BS2 S.A.' },
    { id: '208', name: 'Banco BTG Pactual S.A.' },
    { id: '336', name: 'Banco C6 S.A – C6 Bank' },
    { id: '473', name: 'Banco Caixa Geral – Brasil S.A.' },
    { id: '412', name: 'Banco Capital S.A.' },
    { id: '040', name: 'Banco Cargill S.A.' },
    { id: '368', name: 'Banco Carrefour' },
    { id: '266', name: 'Banco Cédula S.A.' },
    { id: '739', name: 'Banco Cetelem S.A.' },
    { id: '233', name: 'Banco Cifra S.A.' },
    { id: '745', name: 'Banco Citibank S.A.' },
    { id: '241', name: 'Banco Clássico S.A.' },
    { id: '756', name: 'Banco Cooperativo do Brasil S.A. – BANCOOB' },
    { id: '748', name: 'Banco Cooperativo Sicredi S.A.' },
    { id: '222', name: 'Banco Credit Agricole Brasil S.A.' },
    { id: '505', name: 'Banco Credit Suisse (Brasil) S.A.' },
    { id: '069', name: 'Banco Crefisa S.A.' },
    { id: '003', name: 'Banco da Amazônia S.A.' },
    { id: '083', name: 'Banco da China Brasil S.A.' },
    { id: '707', name: 'Banco Daycoval S.A.' },
    { id: '051', name: 'Banco de Desenvolvimento do Espírito Santo S.A.' },
    { id: '300', name: 'Banco de La Nacion Argentina' },
    { id: '495', name: 'Banco de La Provincia de Buenos Aires' },
    { id: '494', name: 'Banco de La Republica Oriental del Uruguay' },
    { id: '335', name: 'Banco Digio S.A' },
    { id: '001', name: 'Banco do Brasil S.A.' },
    { id: '047', name: 'Banco do Estado de Sergipe S.A.' },
    { id: '037', name: 'Banco do Estado do Pará S.A.' },
    { id: '041', name: 'Banco do Estado do Rio Grande do Sul S.A.' },
    { id: '004', name: 'Banco do Nordeste do Brasil S.A.' },
    { id: '196', name: 'Banco Fair Corretora de Câmbio S.A' },
    { id: '265', name: 'Banco Fator S.A.' },
    { id: '224', name: 'Banco Fibra S.A.' },
    { id: '626', name: 'Banco Ficsa S.A.' },
    { id: '094', name: 'Banco Finaxis S.A.' },
    { id: '612', name: 'Banco Guanabara S.A.' },
    { id: '012', name: 'Banco Inbursa S.A.' },
    { id: '604', name: 'Banco Industrial do Brasil S.A.' },
    { id: '653', name: 'Banco Indusval S.A.' },
    { id: '077', name: 'Banco Inter S.A.' },
    { id: '249', name: 'Banco Investcred Unibanco S.A.' },
    { id: '184', name: 'Banco Itaú BBA S.A.' },
    { id: '029', name: 'Banco Itaú Consignado S.A.' },
    { id: '479', name: 'Banco ItauBank S.A' },
    { id: '376', name: 'Banco J. P. Morgan S.A.' },
    { id: '074', name: 'Banco J. Safra S.A.' },
    { id: '217', name: 'Banco John Deere S.A.' },
    { id: '076', name: 'Banco KDB S.A.' },
    { id: '757', name: 'Banco KEB HANA do Brasil S.A.' },
    { id: '600', name: 'Banco Luso Brasileiro S.A.' },
    { id: '243', name: 'Banco Máxima S.A.' },
    { id: '720', name: 'Banco Maxinvest S.A.' },
    { id: '389', name: 'Banco Mercantil de Investimentos S.A.' },
    { id: '389', name: 'Banco Mercantil do Brasil S.A.' },
    { id: '370', name: 'Banco Mizuho do Brasil S.A.' },
    { id: '746', name: 'Banco Modal S.A.' },
    { id: '066', name: 'Banco Morgan Stanley S.A.' },
    { id: '456', name: 'Banco MUFG Brasil S.A.' },
    { id: '007', name: 'Banco Nacional de Desenvolvimento Econômico e Social – BNDES' },
    { id: '169', name: 'Banco Olé Bonsucesso Consignado S.A.' },
    { id: '111', name: 'Banco Oliveira Trust Dtvm S.A' },
    { id: '079', name: 'Banco Original do Agronegócio S.A.' },
    { id: '212', name: 'Banco Original S.A.' },
    { id: '712', name: 'Banco Ourinvest S.A.' },
    { id: '623', name: 'Banco PAN S.A.' },
    { id: '611', name: 'Banco Paulista S.A.' },
    { id: '643', name: 'Banco Pine S.A.' },
    { id: '658', name: 'Banco Porto Real de Investimentos S.A.' },
    { id: '747', name: 'Banco Rabobank International Brasil S.A.' },
    { id: '633', name: 'Banco Rendimento S.A.' },
    { id: '741', name: 'Banco Ribeirão Preto S.A.' },
    { id: '120', name: 'Banco Rodobens S.A.' },
    { id: '422', name: 'Banco Safra S.A.' },
    { id: '033', name: 'Banco Santander (Brasil) S.A.' },
    { id: '743', name: 'Banco Semear S.A.' },
    { id: '754', name: 'Banco Sistema S.A.' },
    { id: '630', name: 'Banco Smartbank S.A.' },
    { id: '366', name: 'Banco Société Générale Brasil S.A.' },
    { id: '637', name: 'Banco Sofisa S.A.' },
    { id: '464', name: 'Banco Sumitomo Mitsui Brasileiro S.A.' },
    { id: '082', name: 'Banco Topázio S.A.' },
    { id: '634', name: 'Banco Triângulo S.A.' },
    { id: '018', name: 'Banco Tricury S.A.' },
    { id: '655', name: 'Banco Votorantim S.A.' },
    { id: '610', name: 'Banco VR S.A.' },
    { id: '119', name: 'Banco Western Union do Brasil S.A.' },
    { id: '124', name: 'Banco Woori Bank do Brasil S.A.' },
    { id: '348', name: 'Banco Xp S/A' },
    { id: '081', name: 'BancoSeguro S.A.' },
    { id: '021', name: 'BANESTES S.A. Banco do Estado do Espírito Santo' },
    { id: '755', name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.' },
    { id: '268', name: 'Barigui Companhia Hipotecária' },
    { id: '250', name: 'BCV – Banco de Crédito e Varejo S.A.' },
    { id: '144', name: 'BEXS Banco de Câmbio S.A.' },
    { id: '253', name: 'Bexs Corretora de Câmbio S/A' },
    { id: '134', name: 'Bgc Liquidez Dtvm Ltda' },
    { id: '017', name: 'BNY Mellon Banco S.A.' },
    { id: '301', name: 'Bpp Instituição De Pagamentos S.A' },
    { id: '126', name: 'BR Partners Banco de Investimento S.A.' },
    { id: '070', name: 'BRB – Banco de Brasília S.A.' },
    { id: '092', name: 'Brickell S.A. Crédito, Financiamento e Investimento' },
    { id: '173', name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.' },
    { id: '142', name: 'Broker Brasil Cc Ltda' },
    { id: '292', name: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.' },
    { id: '011', name: 'C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)' },
    { id: '104', name: 'Caixa Econômica Federal' },
    { id: '288', name: 'Carol Distribuidora de Títulos e Valor Mobiliários Ltda' },
    { id: '130', name: 'Caruana Scfi' },
    { id: '159', name: 'Casa Credito S.A' },
    { id: '016', name: 'Ccm Desp Trâns Sc E Rs' },
    { id: '089', name: 'Ccr Reg Mogiana' },
    { id: '114', name: 'Central Cooperativa De Crédito No Estado Do Espírito Santo' },
    { id: '114-7', name: 'Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda.' },
    { id: '320', name: 'China Construction Bank (Brasil) Banco Múltiplo S.A.' },
    { id: '477', name: 'Citibank N.A.' },
    { id: '180', name: 'Cm Capital Markets Cctvm Ltda' },
    { id: '127', name: 'Codepe Cvc S.A' },
    { id: '163', name: 'Commerzbank Brasil S.A. – Banco Múltiplo' },
    { id: '060', name: 'Confidence Cc S.A' },
    { id: '085', name: 'Coop Central Ailos' },
    { id: '097', name: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda.' },
    { id: '085-x', name: 'Cooperativa Central de Crédito Urbano-CECRED' },
    { id: '090-2', name: 'Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS' },
    { id: '087-6', name: 'Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná' },
    { id: '089-2', name: 'Cooperativa de Crédito Rural da Região da Mogiana' },
    { id: '286', name: 'Cooperativa de Crédito Rural De Ouro' },
    { id: '279', name: 'Cooperativa de Crédito Rural de Primavera Do Leste' },
    { id: '273', name: 'Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel' },
    { id: '098', name: 'Credialiança Ccr' },
    { id: '098-1', name: 'CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL' },
    { id: '010', name: 'Credicoamo' },
    { id: '133', name: 'Cresol Confederação' },
    { id: '182', name: 'Dacasa Financeira S/A' },
    { id: '707', name: 'Banco Daycoval S.A.' },
    { id: '487', name: 'Deutsche Bank S.A. – Banco Alemão' },
    { id: '140', name: 'Easynvest – Título Cv S.A' },
    { id: '149', name: 'Facta S.A. Cfi' },
    { id: '285', name: 'Frente Corretora de Câmbio Ltda.' },
    { id: '278', name: 'Genial Investimentos Corretora de Valores Mobiliários S.A.' },
    { id: '138', name: 'Get Money Cc Ltda' },
    { id: '064', name: 'Goldman Sachs do Brasil Banco Múltiplo S.A.' },
    { id: '177', name: 'Guide Investimentos S.A. Corretora de Valores' },
    { id: '146', name: 'Guitta Corretora de Câmbio Ltda' },
    { id: '078', name: 'Haitong Banco de Investimento do Brasil S.A.' },
    { id: '062', name: 'Hipercard Banco Múltiplo S.A.' },
    { id: '189', name: 'HS Financeira S/A Crédito, Financiamento e Investimentos' },
    { id: '269', name: 'HSBC Brasil S.A. – Banco de Investimento' },
    { id: '271', name: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.' },
    { id: '157', name: 'Icap Do Brasil Ctvm Ltda' },
    { id: '132', name: 'ICBC do Brasil Banco Múltiplo S.A.' },
    { id: '492', name: 'ING Bank N.V.' },
    { id: '139', name: 'Intesa Sanpaolo Brasil S.A. – Banco Múltiplo' },
    { id: '652', name: 'Itaú Unibanco Holding S.A.' },
    { id: '341', name: 'Itaú Unibanco S.A.' },
    { id: '488', name: 'JPMorgan Chase Bank, National Association' },
    { id: '399', name: 'Kirton Bank S.A. – Banco Múltiplo' },
    { id: '293', name: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.' },
    { id: '105', name: 'Lecca Crédito, Financiamento e Investimento S/A' },
    { id: '145', name: 'Levycam Ccv Ltda' },
    { id: '113', name: 'Magliano S.A' },
    { id: '323', name: 'Mercado Pago – Conta Do Mercado Livre' },
    { id: '128', name: 'MS Bank S.A. Banco de Câmbio' },
    { id: '137', name: 'Multimoney Cc Ltda' },
    { id: '014', name: 'Natixis Brasil S.A. Banco Múltiplo' },
    { id: '191', name: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.' },
    { id: '753', name: 'Novo Banco Continental S.A. – Banco Múltiplo' },
    { id: '260', name: 'Nu Pagamentos S.A (Nubank)' },
    { id: '613', name: 'Omni Banco S.A.' },
    { id: '613', name: 'Omni Banco S.A.' },
    { id: '290', name: 'Pagseguro Internet S.A' },
    { id: '254', name: 'Paraná Banco S.A.' },
    { id: '326', name: 'Parati – Crédito Financiamento e Investimento S.A.' },
    { id: '194', name: 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda' },
    { id: '174', name: 'Pernambucanas Financ S.A' },
    { id: '100', name: 'Planner Corretora De Valores S.A' },
    { id: '125', name: 'Plural S.A. – Banco Múltiplo' },
    { id: '093', name: 'Pólocred Scmepp Ltda' },
    { id: '108', name: 'Portocred S.A' },
    { id: '283', name: 'Rb Capital Investimentos Dtvm Ltda' },
    { id: '101', name: 'Renascenca Dtvm Ltda' },
    { id: '270', name: 'Sagitur Corretora de Câmbio Ltda.' },
    { id: '751', name: 'Scotiabank Brasil S.A. Banco Múltiplo' },
    { id: '276', name: 'Senff S.A. – Crédito, Financiamento e Investimento' },
    { id: '545', name: 'Senso Ccvm S.A' },
    { id: '190', name: 'Servicoop' },
    { id: '183', name: 'Socred S.A' },
    { id: '299', name: 'Sorocred Crédito, Financiamento e Investimento S.A.' },
    { id: '118', name: 'Standard Chartered Bank (Brasil) S/A–Bco Invest.' },
    { id: '197', name: 'Stone Pagamentos S.A' },
    { id: '340', name: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.' },
    { id: '095', name: 'Travelex Banco de Câmbio S.A.' },
    { id: '143', name: 'Treviso Corretora de Câmbio S.A.' },
    { id: '131', name: 'Tullett Prebon Brasil Cvc Ltda' },
    { id: '129', name: 'UBS Brasil Banco de Investimento S.A.' },
    { id: '091-4', name: 'Unicred Central do Rio Grande do Sul' },
    { id: '91', name: 'Unicred Central Rs' },
    { id: '136', name: 'Unicred Cooperativa' },
    { id: '099', name: 'UNIPRIME Central' },
    {
        id: '084',
        name: 'Uniprime Norte do Paraná',
    },
    { id: '298', name: 'Vips Cc Ltda' },
    { id: '310', name: 'Vortx Distribuidora de Títulos e Valores Mobiliários Ltda' },
    { id: '102', name: 'Xp Investimentos S.A' },
];
