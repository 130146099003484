import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useAuth } from '../../context/AuthContext';
import server from '../../../../global/server';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';

const Login = props => {
    const { verifySession, setUser, logged } = useAuth();
    const navigation = useNavigate();
    const [hasError, setError] = useState();
    useHandleErrors(hasError);

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
        initialValues: { username: '', password: '' },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const { data } = await server.post('auth/login', values);
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                setUser(data.user);
                verifySession();
                setSubmitting(false);
                navigation('/dashboard');
            } catch (error) {
                setSubmitting(false);
                setError(error);
            }
        },
    });

    useEffect(() => {
        if (logged) {
            navigation('/dashboard');
        }
    }, [logged]);

    return (
        <PanelAuth>
            <div>
                {/* <Logo>
                    <img width={250} src={logo}sx={{ marginBottom: 20 }} />
                </Logo> */}
                <BoxAuth>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" gap={2}>
                            <Grid>
                                <Title>
                                    <Typography variant="h6">Faça o login</Typography>
                                </Title>
                            </Grid>
                            <Grid>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Login"
                                    type="text"
                                    name="username"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.username}
                                    //disabled={isOffline}
                                    error={errors.name}
                                    helperText={errors.name}
                                />
                            </Grid>
                            <Grid>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Senha"
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    error={errors.password}
                                    helperText={errors.password}
                                    //disabled={isOffline}
                                />
                            </Grid>

                            <Grid>
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    loading={isSubmitting}
                                    variant="contained"
                                    disabled={!(values.username && values.password) || isSubmitting}
                                >
                                    Entrar
                                </LoadingButton>
                            </Grid>
                            {/* <Grid>
                                <LoadingButton onClick={() => navigation('/profile/login')} fullWidth variant="text" disabled={isSubmitting}>
                                    Acesso profissional
                                </LoadingButton>
                            </Grid>
                            <Grid>
                                <LoadingButton onClick={() => navigation('/profile/register')} fullWidth variant="text" disabled={isSubmitting}>
                                    Criar curriculo
                                </LoadingButton>
                            </Grid> */}
                        </Grid>
                    </form>
                </BoxAuth>
            </div>
        </PanelAuth>
    );
};

export default Login;

const PanelAuth = styled.div`
    width: 100%;
    height: 100%;
    display: flexbox;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #eee;
`;

const BoxAuth = styled(Grid)`
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 2rem;
    width: 350px;
    background: white;
`;

const Title = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const BoxAuthContainer = styled.div`
    padding: 1px;
`;
