import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const AppContext = createContext({});

export const AppProvider = props => {
    const [loading, switchLoading] = useState('none');
    const [isOffline, setOnline] = useState(false);
    const [orderId, setOrderId] = useState();

    const network = e => {
        const { type, returnValue } = e;
        if (type === 'offline') {
            setOnline(true);
        }

        if (type === 'online') {
            setOnline(false);
        }
    };

    useEffect(() => {
        const online = window.addEventListener('online', network);
        const offline = window.addEventListener('offline', network);

        return () => {
            window.removeEventListener('online', network);
            window.removeEventListener('offline', network);
        };
    });

    const setLoading = bool => {
        switchLoading(bool ? 'flex' : 'none');
    };
    return (
        <AppContext.Provider
            value={{
                loading,
                setLoading,
                isOffline,
                orderId,
                setOrderId,
            }}
        >
            <>
                {props.children}
                <Loading loading={loading}>
                    <CircularProgress />
                </Loading>
            </>
        </AppContext.Provider>
    );
};

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) throw 'useApp context inside corret provider';

    return context;
};

const Loading = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: ${props => props.loading};
    z-index: 999;
    align-items: center;
    justify-content: center;
`;
