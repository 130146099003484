import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useAuth } from '../../Auth/context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default ({ data, mutate }) => {
    const { user } = useAuth();
    const navigation = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { values, errors, handleChange, handleSubmit, handleBlur, handleReset, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            username: '',
            team: '',
            status: true,
            createdAt: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (Boolean(data?.id)) {
                    await server.put(`/users/${data.id}`, values);
                } else {
                    const response = await server.post(`/users/`, values);
                    mutate();
                    navigation(`/users/${response.data.data.id}`);
                    return
                }

                enqueueSnackbar('Dados alterados');
                setSubmitting(false);
            } catch (error) {
                console.log(error.response);
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const { name, team, status, username, createdAt } = data;
            setValues({ name, team, status, username, createdAt }, false);
        }
    }, [data]);

    return (
        <>
            <Container fixed sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    {Boolean(data?.id) && (
                        <Grid item xs={12}>
                            <Stack alignItems="flex-end">
                                <Alert>Cadastrado desde {new Date(values.createdAt).toLocaleString('pt-BR')}</Alert>
                            </Stack>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Nome"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={errors.name}
                            helperText={errors.name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Login"
                            type="text"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            error={errors.username}
                            helperText={errors.username}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Documento"
                            type="text"
                            name="document"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.document}
                            error={errors.document}
                            helperText={errors.document}
                        />
                    </Grid> */}
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Função</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="team"
                                value={values.team}
                                label="Sexo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={data?.id === user.id}
                            >
                                <MenuItem value="adm">Administrador</MenuItem>
                                <MenuItem value="manager">Gerente</MenuItem>
                                <MenuItem value="assistant">Assistente</MenuItem>
                            </Select>
                            <FormHelperText>{errors.gender}</FormHelperText>
                        </FormControl>
                    </Grid>

                    {/* <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Telefone"
                            type="text"
                            name="cellphone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cellphone}
                            error={errors.cellphone}
                            helperText={errors.cellphone}
                        />
                    </Grid> */}

                    {/* <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Email"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={errors.email}
                            helperText={errors.email}
                        />
                    </Grid> */}

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Senha"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={errors.password}
                            helperText={errors.password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={values.status} disabled={data?.id === user.id} onChange={handleChange} name="status" />}
                                label={values.status ? 'Ativo' : 'Inativo'}
                            />
                        </FormGroup>
                    </Grid>
                    <Divider />
                    <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid>
                            <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                                Salvar dados
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
