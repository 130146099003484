import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import LoadingContent from '../../../components/LoadingContent';
import { useFetch } from '../../../hooks/useFetch';
import AddressForm from './AddressForm';
import ProfileForm from './ProfileForm';
import SettingsForm from './SettingsForm';
import CustomerSales from './CustomerSales';

export default function CustomerTabs({ activeTab }) {
    const { id } = useParams();
    const { data, error, mutate, isFetching } = useFetch(`/customers/${id}`);
   
    if (!data) return <LoadingContent label="Carregando dados..." />;

    return (
        <TabsContainer>
            <TabBody activeBody={activeTab === 0}>
                <ProfileForm data={data} mutate={mutate} />
            </TabBody>

            <TabBody activeBody={activeTab === 1}>
                <AddressForm data={data} mutate={mutate}/>
            </TabBody>
            <TabBody activeBody={activeTab === 2}>
                <SettingsForm data={data} mutate={mutate}/>
            </TabBody>
            <TabBody activeBody={activeTab === 3}>
                <CustomerSales mutate={mutate}/>
            </TabBody>
        </TabsContainer>
    );
}

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
